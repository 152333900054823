//
// This module concentrates all Open Questionnaire participation funcionality
//
import sharedConstants from '@/shared/constants.json'
import { axiosBase, camelcaseTransformRequest, camelcaseTransformResponse } from '../../../api/axios-base' // eslint-disable-line import/no-cycle

const getInitialState = () => ({
  breadcrumbs: [],
  taskShufflingCache: {}
})

function shuffle(array) {
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i

    // swap elements array[i] and array[j]
    // we use "destructuring assignment" syntax to achieve that
    // you'll find more details about that syntax in later chapters
    // same can be written as:
    // let t = array[i]; array[i] = array[j]; array[j] = t
    [array[i], array[j]] = [array[j], array[i]]
  }
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    currentTaskCount(state) {
      const { breadcrumbs } = state

      return breadcrumbs.length
    },
    currentTaskData(state) {
      const { breadcrumbs } = state

      if (breadcrumbs.length > 0) {
        // Last task is current
        return breadcrumbs[breadcrumbs.length - 1]
      }

      return null
    },
    currentTaskIdentifier(state, getters) {
      const { currentTaskData } = getters

      return currentTaskData ? currentTaskData.identifier : null
    },
    currentTaskIsOfType: (state, getters) => (taskType) => {
      const { currentTaskData } = getters

      return currentTaskData && currentTaskData.taskType === taskType
    },
    currentTaskIsSES(state, getters) {
      return getters.currentTaskIsOfType(sharedConstants.TASK_TYPE.SES)
    },
    currentTaskIsNPS(state, getters) {
      return getters.currentTaskIsOfType(sharedConstants.TASK_TYPE.NPS)
    },
    currentTaskIsRating(state, getters) {
      return getters.currentTaskIsOfType(sharedConstants.TASK_TYPE.RATING)
    },
    currentTaskIsSimpleChoice(state, getters) {
      return getters.currentTaskIsOfType(sharedConstants.TASK_TYPE.SIMPLE_CHOICE)
    },
    currentTaskIsMultipleChoice(state, getters) {
      return getters.currentTaskIsOfType(sharedConstants.TASK_TYPE.MULTIPLE_CHOICE)
    },
    currentTaskIsOpenQuestion(state, getters) {
      return getters.currentTaskIsOfType(sharedConstants.TASK_TYPE.OPEN_QUESTION)
    }
  },
  mutations: {
    activateTask(state, data) {
      // Activates a new task based on it's taskData
      // Optionally resets the breadcrumbs (just starting participation)
      //
      // Adds the new task to the breadcrumb trail
      // Shuffles option ordering if necessary

      const { taskData } = { ...data }
      const resetBreadcrumbs = data.resetBreadcrumbs ? data.resetBreadcrumbs : null

      const TASK_TYPE = sharedConstants

      // Shuffle options before adding task to breadcrumbs
      if (
        taskData.rotateOptionsPosition
        && [TASK_TYPE['SIMPLE_CHOICE'], TASK_TYPE['MULTIPLE_CHOICE']].includes(taskData.task_type)
      ) {
        if (state.taskShufflingCache[taskData.identifier] !== undefined) {
          taskData.options = [...state.taskShufflingCache[taskData.identifier]]
        } else {
          const reorderedOptions = [...taskData.options]
          shuffle(reorderedOptions)

          taskData.options = reorderedOptions

          // taskShufflingCache property only used on-demand, no impact on UI
          state.taskShufflingCache[taskData.identifier] = [...reorderedOptions]
        }
      }

      if (taskData.customOptionText) {
        taskData.options = [
          ...taskData.options,
          {
            description: taskData.customOptionText,
            details: '',
            identifier: 'custom',
            mediaFile: null,
            order: 1000000000
          }
        ]
      }

      if (resetBreadcrumbs) {
        state.breadcrumbs = [{ ...taskData }]
      } else {
        state.breadcrumbs = [...state.breadcrumbs, { ...taskData }]
      }
    },
    goToPreviousStep(state) {
      // Removes the last Task in the breadcrumb trail
      state.breadcrumbs.splice(-1, 1)
    },
    resetState(state) {
      Object.assign(state, getInitialState())
    }
  },
  actions: {
    async updateParticipationWithFormData({
      commit, dispatch, getters, rootState, rootGetters
    }) {
      // - Updates an existing Response, or creates a new one based on the
      //   current formData (assuming it's already valid)
      // - Moves to next task (if provided by API)
      // - Changes Participation and Participant statuses if indicated by API
      // - Updates the Project data on Participation status change

      // Indicate that an update has started
      commit('participationParticipation/setUpdateInProgress', true, { root: true })

      const { currentTaskData } = getters
      const { participationForm: { formData } } = rootState

      const projectIdentifier = rootGetters['participationProject/projectIdentifier']
      const activityIdentifier = rootGetters['participationActivity/activityIdentifier']
      const credentialsQueryString = rootGetters['participationParticipant/credentialsQueryString']
      const currentTaskResponse = rootGetters['participationParticipation/currentTaskResponse']

      // Create base Response data from formData
      const postData = {
        ...formData,
        options: formData.options.filter((option) => option !== 'custom'),
        responseMediaFiles: formData.responseMediaFiles.filter(
          (mediaFile) => mediaFile !== null && mediaFile.mediaFile
        ),
        taskIdentifier: currentTaskData.identifier
      }

      if (currentTaskResponse) {
        // Add response identifier to update existing Response on PUT request
        postData.identifier = currentTaskResponse.identifier
      }

      let httpResponse = null

      if (currentTaskResponse) {
        // Update existing Response
        httpResponse = await axiosBase.put(
          `/projects/${projectIdentifier}/activities/${activityIdentifier}/responses/
${currentTaskResponse.identifier}/${credentialsQueryString}`,
          postData,
          {
            // TODO: transform request and response globally on all Axios requests
            transformRequest: [camelcaseTransformRequest, ...axiosBase.defaults.transformRequest],
            transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
          }
        )
      } else {
        // Create a new Response
        httpResponse = await axiosBase.post(
          `/projects/${projectIdentifier}/activities/${activityIdentifier}/responses/${credentialsQueryString}`,
          postData,
          {
            // TODO: transform request and response globally on all Axios requests
            transformRequest: [camelcaseTransformRequest, ...axiosBase.defaults.transformRequest],
            transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
          }
        )
      }

      if (currentTaskResponse) {
        // Update current response data on Participation
        commit('participationParticipation/updateResponseData', postData, { root: true })
      } else {
        // Add new Response data to Participation
        commit(
          'participationParticipation/addResponseToParticipation',
          { identifier: httpResponse.data.response.identifier, ...postData },
          { root: true }
        )
      }

      const { data: { nextTask, newParticipationStatus, newParticipantStatus } } = httpResponse

      if (nextTask) {
        // Participation hasn't finished yet, move to next Task
        commit('activateTask', { taskData: nextTask })

        // Re-load form for next task
        dispatch('participationForm/updateFormDataFromState', null, { root: true })
      } else {
        // If necessary, mark the Participant first as it's modification will trigger the main UI change
        if (newParticipantStatus) {
          commit('participationParticipant/setParticipantStatus', newParticipantStatus, { root: true })
        }

        // At least the Participation's status has changed for sure
        if (newParticipationStatus) {
          dispatch('participationParticipation/setParticipationStatus', newParticipationStatus, { root: true })

          // Update Participation data on the Activity
          commit(
            'participationActivity/updateActivityParticipationData',
            rootState.participationParticipation.participationData,
            { root: true }
          )

          // Update project data
          await dispatch(
            'participationProject/getProjectData',
            { projectIdentifier, forceUpdate: true },
            { root: true }
          )
        } else {
          // Shouldn't happen, throw an exception
          // TODO: add exception
        }
      }

      // Update has finished
      commit('participationParticipation/setUpdateInProgress', false, { root: true })
      return true
    }
  }
}
