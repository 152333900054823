import { axiosBase, camelcaseTransformResponse } from '../../../api/axios-base' // eslint-disable-line import/no-cycle

export default {
  namespaced: true,
  state: {
    participantsData: null
  },
  getters: {
    userIdentifierMappedParticipantsData(state) {
      if (state.participantsData) {
        return state.participantsData.reduce(
          (acc, item) => {
            if (item.user) {
              acc[item.user.identifier] = { ...item }
            }

            return acc
          }, {}
        )
      }

      return null
    },
    participantDataByUserIdentifier: (state, getters) => (
      userIdentifier
    ) => getters.userIdentifierMappedParticipantsData[userIdentifier]
  },
  mutations: {
    setParticipantsData(state, data) {
      state.participantsData = data
    }
  },
  actions: {
    getParticipantsData({ commit, rootGetters }, { projectIdentifier }) {
      const credentialsQueryString = rootGetters['participationParticipant/credentialsQueryString']

      return new Promise((resolve, reject) => {
        axiosBase.get(`/projects/${projectIdentifier}/participants/${credentialsQueryString}`,
          {
          // TODO: transform request and response globally on all Axios requests
            transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
          })
          .then((response) => {
            commit('setParticipantsData', response.data.results)
            resolve(response.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  }
}
