import { ToastProgrammatic as Toast } from 'buefy'

const triggerAlert = (message, type, indefinite) => {
  const options = {
    message,
    position: 'is-top',
    type,
    indefinite: indefinite || false
  }

  if (indefinite) {
    options.indefinite = true
    options.duration = 10000000 // Didn't work only specifying indefinite
  } else {
    options.duration = 5000
  }

  Toast.open(options)
}

export default {
  namespaced: true,
  state: {},
  actions: {
    triggerErrorAlert(context, { message, indefinite }) {
      triggerAlert(message, 'is-danger', indefinite)
    },
    triggerSuccessAlert(context, { message }) {
      triggerAlert(message, 'is-success')
    },
    triggerDataFetchErrorAlert({ dispatch }) {
      dispatch('triggerErrorAlert', {
        indefinite: true,
        message: 'Ha ocurrido un error al intentar obtener la información necesaria. Por favor recarga la '
          + 'página e intenta nuevamente.'
      })
    }
  }
}
