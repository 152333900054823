import {
  required, confirmed, length, email
} from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'

extend('required', {
  ...required,
  message: 'Este campo es obligatorio.'
})

extend('requiredOption', {
  ...required,
  message: 'Debés ingresar al menos 2 opciones.'
})

extend('requiredCheck', {
  ...required,
  message: 'Debés ingresar alguna opción.'
})

extend('email', {
  ...email,
  message: 'Debe ingresar un email válido.'
})

extend('confirmed', {
  ...confirmed,
  message: 'La confirmación de este campo no coincide.'
})

extend('length', {
  ...length,
  message: 'Este campo debe tener 2 opciones como mínimo.'
})
