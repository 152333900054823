import ls from 'local-storage'
import { get } from 'lodash'

import sharedConstants from '@/shared/constants.json'
import sharedErrorCodes from '@/shared/error_codes.json'
import offerwiseURL from '@/components/participation/offerwiseURL'
import participationStages from '@/components/participation/participationStages'
import { // eslint-disable-line import/no-cycle
  axiosBase, camelcaseTransformRequest, camelcaseTransformResponse
} from '../../../api/axios-base'
import router from '../../../router' // eslint-disable-line import/no-cycle

const generateCredentialsQueryString = (identifier, secretKey) => (
  `?participant_identifier=${identifier}&participant_secret_key=${secretKey}`)

const getInitialState = () => ({
  // The API fails to create the participant because no new participants are allowed
  noNewParticipantsAllowed: false,
  // Stores the participant's data for a project (by hashed identifier) as a Single source of truth
  participantData: null
})

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    setNoNewParticipantsAllowed(state) {
      Object.assign(state, {
        noNewParticipantsAllowed: true,
        participantData: null
      })
    },
    setParticipantData(state, participantData) {
      Object.assign(state, {
        noNewParticipantsAllowed: false,
        participantData
      })
    },
    setParticipantComplete(state) {
      state.participantData.status = sharedConstants.PARTICIPANT_STATUS.COMPLETE
    },
    setParticipantDisqualified(state) {
      state.participantData.status = sharedConstants.PARTICIPANT_STATUS.DISQUALIFIED
    },
    setParticipantStatus(state, value) {
      state.participantData.status = value
    },
    resetState(state) {
      Object.assign(state, getInitialState())
    }
  },
  getters: {
    currentParticipationStage: (state, getters, rootState) => {
      // Depending on the project and participant's status, decides what should be the
      // current stage. It may deny access to the project altogether.
      const { participationProject: { projectData } } = rootState

      if (!projectData) return participationStages.noProjectData

      const { participantData } = state

      // Community project
      if (
        projectData.project_type === sharedConstants['PROJECT_TYPE']['COMMUNITY']
      ) {
        return participationStages.projectDetail
      }

      // Open Questionnaire project
      if ((projectData.relevant_tnc_required && !participantData.relevantTncAcceptedOn)
        || (projectData.project_tnc_required && !participantData.projectTncAcceptedOn)) {
        // The participant still needs to accept some terms and conditions
        return participationStages.termsAndConditions
      }

      return participationStages.activity
    },
    credentialsQueryString(state) {
      if (state.participantData) {
        const { identifier, secretKey } = state.participantData

        return generateCredentialsQueryString(identifier, secretKey)
      }

      return ''
    },
    participantComplete(state) {
      return state.participantData
        && state.participantData.status === sharedConstants['PARTICIPANT_STATUS']['COMPLETE']
    },
    participantDisqualified(state) {
      return state.participantData
        && state.participantData.status === sharedConstants['PARTICIPANT_STATUS']['DISQUALIFIED']
    },
    participantInProgress(state) {
      return state.participantData
        && state.participantData.status === sharedConstants['PARTICIPANT_STATUS']['IN_PROGRESS']
    },
    participantNotStarted(state) {
      return state.participantData
        && state.participantData.status === sharedConstants['PARTICIPANT_STATUS']['NOT_STARTED']
    }
  },
  actions: {
    async getParticipantData({
      commit, dispatch, rootGetters, rootState, state
    }, { projectIdentifier, owid }) {
      const { enable_offerwise_panels: enableOfferwisePanels } = rootState.participationProject.projectData
      const requestOwid = (enableOfferwisePanels && owid) ? owid : ''

      // Fetches a participant's credentials for a specific project identifier and
      // commits it to the state

      // Only fetch from server in case it isn't already loaded
      if (!state.participantData
        || (!rootState.participationProject.projectData
          || rootState.participationProject.projectData.identifier !== projectIdentifier
        )
      ) {
        const isAnonymousProject = rootGetters['participationProject/isAnonymousProject']
        let queryString = ''
        // Only anonymous projects' credentials will be saved to local storage
        // Identified projects' credentials are obtained through the user
        let updateParticipantCredentials = isAnonymousProject
        let allParticipantCredentials = null

        if (isAnonymousProject) {
          // Check if the project is anonymous and the participant identifier is loaded on local storage
          allParticipantCredentials = ls.get('participantCredentials') || {}
          const participantCredentials = allParticipantCredentials[projectIdentifier]

          if (participantCredentials !== undefined) {
            const { identifier, secretKey } = participantCredentials
            queryString = generateCredentialsQueryString(identifier, secretKey)
            updateParticipantCredentials = false // No need to update if they were obtained from local storage
          }
        }

        try {
          const { data: participantData } = await axiosBase.post(
            `/projects/${projectIdentifier}/participants/get-or-create/${queryString}`,
            {
              owid: requestOwid
            },
            {
              // TODO: transform request and response globally on all Axios requests
              transformRequest: [camelcaseTransformRequest, ...axiosBase.defaults.transformRequest],
              transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
            }
          )

          commit('setParticipantData', participantData)

          if (updateParticipantCredentials) {
            // Save new credentials to local storage
            ls.set('participantCredentials', {
              ...allParticipantCredentials,
              [projectIdentifier]: { identifier: participantData.identifier, secretKey: participantData.secretKey }
            })
          }
        } catch (error) {
          if (
            get(error, 'response.data.code', null)
            === sharedErrorCodes['PARTICIPANT_GET_OR_CREATE_ACTION']['NO_NEW_PARTICIPANTS_ALLOWED']
          ) {
            // Project is not accepting new anonymous participants

            // Redirect back to Offerwise if necessary
            if (requestOwid) {
              commit('participationParticipation/setRedirecting', true, { root: true })

              window.location.href = offerwiseURL(`/processsurvey.php?status=quotafull&owid=${requestOwid}`)
            } else {
              commit('setNoNewParticipantsAllowed')
            }

            return false
          }

          dispatch('alerts/triggerDataFetchErrorAlert', null, { root: true })
          return false
        }
      }

      // Success
      return true
    },
    async routeParticipant({
      dispatch, getters, rootGetters, rootState, state
    }, { currentStage, projectIdentifier, owid }) {
      // Determines where on the participation process should the current participant be located
      // and, if not currently on that route, routes them to the corresponding page.

      // We need to have the project's and participant's data
      await dispatch('participationProject/getProjectData', { projectIdentifier }, { root: true })

      const { projectData } = rootState.participationProject

      if (!projectData) {
        // Project data fetching failed
        return
      }

      if (
        projectData.identification_requirements
        !== sharedConstants['PROJECT_IDENTIFICATION_REQUIREMENT']['ANONYMOUS'] && !rootGetters.loggedIn
      ) {
        // Project not anonymous and user not logged in
        router.push({ name: participationStages.login, params: { targetRoute: router.currentRoute } })
        return
      }

      // Check if the user can access the Project
      const getParticipantDataResult = await dispatch('getParticipantData', { projectIdentifier, owid })

      // Failed to fetch Participant data
      if (!getParticipantDataResult) return

      // When access to the project is denied, don't route the user, the access denied component will be rendered
      if (rootGetters['participationProject/accessDenied']) return

      if (state.noNewParticipantsAllowed) return

      // Route the participant to their destination
      const targetStage = getters.currentParticipationStage

      if (
        projectData.project_type === sharedConstants.PROJECT_TYPE.COMMUNITY
        && ((
          [participationStages.projectDetail, participationStages.projectHome].includes(targetStage)
          && [
            participationStages.projectHome, participationStages.projectDetail,
            participationStages.activity, participationStages.forum
          ].includes(currentStage)
        ) || (
          currentStage === participationStages.termsAndConditions
          && targetStage === participationStages.questionnaire
        ))
      ) {
        // Don't redirect
        return
      }

      if (currentStage !== targetStage) {
        let targetRoute = null
        const routeParams = { projectIdentifier }

        if (targetStage === participationStages.termsAndConditions) {
          targetRoute = { name: participationStages.termsAndConditions, params: routeParams }
        } else if (targetStage === participationStages.activity) {
          const activityIdentifier = projectData.activities[0].identifier

          targetRoute = {
            name: participationStages.activity,
            params: { ...routeParams, activityIdentifier }
          }
        } else if (targetStage === participationStages.projectHome) {
          targetRoute = { name: participationStages.projectHome, params: routeParams }
        }

        router.push(targetRoute)
      }
    },
    async updateTncStatus({ commit, dispatch, getters }, { projectIdentifier, tncAction }) {
      try {
        const { data: participantData } = await axiosBase.post(
          `/projects/${projectIdentifier}/terms-and-conditions/${getters.credentialsQueryString}`, tncAction, {
            // TODO: transform request and response globally on all Axios requests
            transformRequest: [camelcaseTransformRequest, ...axiosBase.defaults.transformRequest],
            transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
          }
        )
        // Update the participant's data from the response
        commit('setParticipantData', participantData)
        return true
      } catch {
        dispatch('alerts/triggerErrorAlert', {
          message: (
            `Ha ocurrido un error al intentar ${tncAction === 'accept' ? 'aceptar' : 'rechazar'} los`
            + ' Términos y Condiciones. Por favor verifica tu conexión e intenta nuevamente.')
        }, { root: true })
        return false
      }
    },
    async startParticipatingInProject({
      commit, dispatch, getters, rootState
    }) {
      console.log(rootState)
      const projectIdentifier = rootState.participationProject.projectData.identifier

      try {
        const { data: participantData } = await axiosBase.post(
          `/projects/${projectIdentifier}/participants/start-participating/${getters.credentialsQueryString}`, {}, {
            // TODO: transform request and response globally on all Axios requests
            transformRequest: [camelcaseTransformRequest, ...axiosBase.defaults.transformRequest],
            transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
          }
        )

        // Update the participant's data from the response
        commit('setParticipantData', participantData)

        return true
      } catch {
        dispatch('alerts/triggerDataFetchErrorAlert', null, { root: true })
        return false
      }
    }
  }
}
