<template>
  <div>
    <centered-logo-simple-header class="is-hidden-desktop" />
    <div class="split-layout larger-top-gap">
      <div class="columns is-desktop is-gapless">
        <div class="column is-three-fifths-desktop is-two-fifths-widescreen">
          <div class="form-container simple-form">
            <div v-if="invitationState.loading">
              <b-loading
                :is-full-page="false"
                :active="true"
                :can-cancel="false"
              />
            </div>
            <div class="form-box">
              <div class="layer">
                <div class="layer-top">
                  <div class="title">
                    <h1>{{ $t('login.access_the_platform') }}</h1>
                  </div>

                  <!-- eslint-disable vue/no-v-html -->
                  <b-notification
                    v-if="wrongCred"
                    type="is-danger"
                    aria-close-label="Close notification"
                    role="alert"
                    v-html="$t('login.user_or_password_error')"
                  />
                  <!-- eslint-enable -->

                  <form @submit.prevent="loginUser">
                    <b-field :label="$t('global.email')">
                      <b-input
                        v-model="username"
                        type="email"
                        size="is-medium"
                        icon="envelope"
                        class="input-email"
                        :placeholder="$t('login.enter_email')"
                        :validation-message="$t('validation.enter_valid_email')"
                        required
                      />
                    </b-field>

                    <b-field :label="$t('global.password')">
                      <b-input
                        v-model="password"
                        type="password"
                        size="is-medium"
                        icon="eye"
                        class="input-password"
                        :placeholder="$t('login.enter_password')"
                        :validation-message="$t('validation.enter_valid_password')"
                        required
                        password-reveal
                      />
                    </b-field>

                    <div class="forgot-password-link">
                      <localized-router-link :to="{ name: 'password-reset' }">
                        {{ $t('login.forgot_password') }}
                      </localized-router-link>
                    </div>

                    <div class="field">
                      <p class="control">
                        <button
                          type="submit"
                          class="button is-success"
                          :class="{ 'is-loading': isAuthenticating }"
                        >
                          {{ $t('global.enter') }}
                        </button>
                      </p>
                    </div>
                  </form>
                </div>
                <div class="layer-spacer" />
                <div class="layer-end">
                  <div
                    v-if="invitationState.invitationValid"
                    class="registration-invitation"
                  >
                    <!-- eslint-disable vue/no-v-html -->
                    <div
                      class="invitation-text"
                      v-html="$t('login.signup_invitation')"
                    />
                    <!-- eslint-enable -->
                    <localized-router-link
                      :to="{ name: 'registration' }"
                      class="button is-success is-small"
                    >
                      {{ $t('login.create_account') }}
                    </localized-router-link>
                  </div>
                  <div class="more-info">
                    <p v-html="$t('login.find_more_info')" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <split-layout-right />
      </div>
    </div>
  </div>
</template>

<script>
import CenteredLogoSimpleHeader from '@/components/CenteredLogoSimpleHeader.vue'
import SplitLayoutRight from '@/components/SplitLayoutRight.vue'

export default {
  name: 'Login',
  components: {
    CenteredLogoSimpleHeader,
    SplitLayoutRight
  },
  data() {
    return {
      username: '',
      password: '',
      wrongCred: false, // activates appropriate message if set to true
      passwordInputType: 'password'
    }
  },
  computed: {
    isAuthenticating() {
      return this.$store.state.authenticating
    },
    invitationState() {
      return this.$store.state.Invitations
    }
  },
  methods: {
    loginUser() {
      // call loginUSer action
      if (this.username && this.password) {
        this.wrongCred = false
        this.$store
          .dispatch('loginUser', {
            username: this.username,
            password: this.password
          })
          .then(() => {
            this.wrongCred = false

            const { currentRoute: { params: { targetRoute } } } = this.$router

            this.$router.push(targetRoute || { name: 'proyectos' })
          })
          .catch((err) => {
            console.log(err)
            this.wrongCred = true // if the credentials were wrong set wrongCred to true
          })
      } else {
        this.wrongCred = true
      }
    },
    switchTypePassword() {
      this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password'
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../scss/simple_form.scss';
  @import '../scss/split_layout.scss';

  .registration-invitation {
    display: flex;
    align-items: center;
    background: $blue-ultralight;
    border-radius: 5px;
    padding: 12px 22px;
    font-size: 14px;
    margin: 40px 0 18px;

    .invitation-text {
      flex-grow: 1;
      font-weight: 500;

      b {
        font-weight: 600;
      }
    }

    &::v-deep .button {
      width: auto;
      flex-grow: 0;
    }
  }

  .more-info {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }

  .login-box {
    .notification {
      @include respond(mobile){
        padding: .70rem 2rem .70rem .70rem;
        font-size: 15px;
      }
    }
  }

  .layer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
</style>
