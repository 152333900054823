import { get, pickBy } from 'lodash'

import { EXPORT_STATUS } from '@/shared/constants.json'
import { axiosBase, camelcaseTransformRequest, camelcaseTransformResponse } from '../../../api/axios-base' // eslint-disable-line import/no-cycle

export default {
  namespaced: true,
  state: {
    currentExports: {},
    pollingTimer: null,
    pollingInProgress: false,
    startingExportRequest: false
  },
  getters: {
    pendingExports: (state) => pickBy(
      state.currentExports, (exp) => [EXPORT_STATUS['PENDING'], EXPORT_STATUS['IN_PROGRESS']].includes(exp.status)
    ),
    processingExports: (state, getters) => state.startingExportRequest || Object.keys(getters.pendingExports).length > 0
  },
  mutations: {
    setPollingInProgress(state, value) {
      state.pollingInProgress = value
    },
    setPollingTimer(state, value) {
      state.pollingTimer = value
    },
    setStartingExportRequest(state, value) {
      state.startingExportRequest = value
    },
    updateExportData(state, { exportData, clearStartingExportRequest }) {
      // Receives array of DataExport information and updates dictionary
      // by DataExport id
      const stateUpdate = {
        currentExports: {
          ...state.currentExports,
          ...exportData.reduce((previousValue, item) => {
            previousValue[item.identifier] = item
            return previousValue
          }, {})
        }
      }

      if (clearStartingExportRequest) {
        stateUpdate.startingExportRequest = false
      }

      Object.assign(state, stateUpdate)
    }
  },
  actions: {
    async startPolling({ commit, dispatch }) {
      commit('setPollingTimer', setInterval(async () => {
        await dispatch('pollExportStatus')
      }, 5000))
    },
    async stopPolling({ commit, state }) {
      clearInterval(state.pollingTimer)
      commit('setPollingTimer', null)
    },
    async pollExportStatus({
      commit, dispatch, getters, state
    }) {
      if (state.pollingInProgress) {
        return
      }
      commit('setPollingInProgress', true)

      const response = await axiosBase.get(
        `/exports/?identifiers=${Object.keys(getters.pendingExports).join()}`,
        {
          // TODO: transform request and response globally on all Axios requests
          transformRequest: [camelcaseTransformRequest, ...axiosBase.defaults.transformRequest],
          transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
        }
      )

      const data = get(response, 'data')
      const status = get(response, 'status')

      if (status === 200 && data) {
        commit('updateExportData', { exportData: data })

        data.forEach((exp) => {
          if (exp.status === EXPORT_STATUS['COMPLETED']) {
            window.location.href = exp.filePublicUrl
          } else if (exp.status === EXPORT_STATUS['FAILED']) {
            dispatch(
              'alerts/triggerErrorAlert',
              { message: 'Ha ocurrido un error al generar el archivo de descarga. Por favor intenta nuevamente.' },
              { root: true }
            )
          }
        })
      }

      commit('setPollingInProgress', false)

      const exportIdentifiers = Object.keys(state.currentExports)

      if (
        exportIdentifiers.length === 0
        || exportIdentifiers.every(
          (exportIdentifier) => [EXPORT_STATUS['COMPLETED'], EXPORT_STATUS['FAILED']].includes(
            state.currentExports[exportIdentifier].status
          )
        )
      ) {
        await dispatch('stopPolling')
      }
    },
    async startDataExport({ commit, dispatch, state }, { activityIdentifier, projectIdentifier, fileType }) {
      commit('setStartingExportRequest', true)

      const requestData = { fileType }

      if (activityIdentifier) requestData.activityIdentifier = activityIdentifier
      if (projectIdentifier) requestData.projectIdentifier = projectIdentifier

      let response = null

      try {
        response = await axiosBase.post(
          '/exports/', requestData,
          {
            // TODO: transform request and response globally on all Axios requests
            transformRequest: [camelcaseTransformRequest, ...axiosBase.defaults.transformRequest],
            transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
          }
        )
      } catch {
        dispatch(
          'alerts/triggerErrorAlert',
          {
            message: 'Ha ocurrido un error al solicitar la descarga. Por favor recarga la página e intenta nuevamente.'
          },
          { root: true }
        )
        commit('setStartingExportRequest', false)
        return false
      }

      const { data } = response

      commit('updateExportData', { exportData: [data], clearStartingExportRequest: true })

      if (state.pollingTimer == null) {
        await dispatch('startPolling')
      }
      return true
    }
  }
}
