import { axiosBase } from '../../api/axios-base' // eslint-disable-line import/no-cycle

export default {
  namespaced: true,
  state: {
    userData: JSON.parse(localStorage.getItem('userData') || null)
  },
  getters: {
    userFullName(state) {
      if (state.userData != null) {
        return `${state.userData.first_name} ${state.userData.last_name}`
      }

      return ''
    },
    userEmail(state) {
      if (state.userData != null) {
        return state.userData.email
      }

      return ''
    },
    isParticipant(state) {
      if (state.userData != null) {
        return state.userData.is_participant === true
      }

      return true
    }
  },
  mutations: {
    setUser(state, data) {
      state.userData = data
      localStorage.setItem('userData', JSON.stringify(data))
    }
  },
  actions: {
    getUser(context) {
      axiosBase.get('/users/me/')
        .then((response) => {
          context.commit('setUser', response.data)
        })
    },
    updateUserProfile(context, data) {
      return new Promise((resolve, reject) => {
        axiosBase.patch('/users/me/', data)
          .then((response) => {
            resolve(response)
          })
          .catch(() => {
            reject()
          })
      })
    },
    updateUserPassword(context, data) {
      return new Promise((resolve, reject) => {
        axiosBase.post('/auth/users/set_password/', data)
          .then((response) => {
            resolve(response)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  }
}
