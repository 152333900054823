//
// Interactions -> Interactions
//
// This module fetches the interactions data
//
import { // eslint-disable-line import/no-cycle
  axiosBase, camelcaseTransformResponse
} from '../../../api/axios-base' // eslint-disable-line import/no-cycle

export default {
  strict: true,
  namespaced: true,
  state: {
    // Stores all data from the project interactions
    taskListData: null,
    taskData: null
  },
  mutations: {
    setTaskListData(state, taskListData) {
      state.taskListData = taskListData
    },

    setTaskData(state, taskData) {
      state.taskData = taskData
    }
  },
  actions: {
    async getResponseInitialData(
      { dispatch },
      {
        projectIdentifier, responseIdentifier, taskIdentifier
      }
    ) {
      await dispatch('Projects/getProjectData', { projectIdentifier, edit: false }, { root: true })

      await dispatch('getTaskData', { projectIdentifier, taskIdentifier })

      await dispatch(
        'comments/getCommentsData',
        {
          commentedContentAppLabel: 'projects',
          commentedContentModel: 'taskresponse',
          commentedContentIdentifier: responseIdentifier
        },
        { root: true }
      )
    },

    async getTaskListData({ commit, dispatch }, { projectIdentifier }) {
      try {
        const response = await
        axiosBase.get(
          `/projects/${projectIdentifier}/interactions`,
          {
            // TODO: transform request and response globally on all Axios requests
            transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
          }
        )
        const { data: { results: taskListData } } = response

        commit('setTaskListData', taskListData)
        return true
      } catch (err) {
        dispatch('alerts/triggerErrorAlert', {
          message: (
            'Ha ocurrido un error al intentar obtener la información de las consignas')
        }, { root: true })
        return false
      }
    },

    async getTaskData({ commit, dispatch }, { projectIdentifier, taskIdentifier }) {
      try {
        const response = await axiosBase.get(
          `/projects/${projectIdentifier}/interactions/${taskIdentifier}`,
          {
            // TODO: transform request and response globally on all Axios requests
            transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
          }
        )
        const { data: taskData } = response

        commit('setTaskData', taskData)
        return true
      } catch (err) {
        dispatch('alerts/triggerErrorAlert', {
          message: (
            'Ha ocurrido un error al intentar obtener la información de la consigna')
        }, { root: true })
        return false
      }
    }
  }
}
