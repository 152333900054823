<template>
  <router-link
    v-if="custom"
    :to="localizedTo()"
    custom
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template
      v-for="(_, slot) of $scopedSlots"
      v-slot:[slot]="scope"
    >
      <span :key="slot">
        <slot
          :name="slot"
          v-bind="scope"
        />
      </span>
    </template>
  </router-link>
  <router-link
    v-else
    :to="localizedTo()"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </router-link>
</template>

<script>
// Based on LocalizedLink from vue-lang-router
//
// <localized-router-link> component extends <router-link> and localizes URL
export default {
  name: 'LocalizedRouterLink',
  props: {
    custom: {
      type: Boolean,
      default: false
    },
    to: {
      type: [String, Object],
      required: true
    }
  },
  methods: {
    localizedTo() {
      // If "to" is a string, localize it
      if (typeof this.to === 'string') {
        return this.$localizePath(this.to)
      }

      // If "to" is an object with "path", copy it and localize "path"
      if (typeof this.to === 'object') {
        if (typeof this.to.path === 'string') {
          return this.$localizePath(this.to.path)
        }

        if (typeof this.to.name === 'string') {
          const result = this.$router.resolve(this.to)

          return this.$localizePath(result.route.path)
        }
      }

      // If "to" is an object without "path", just pass it on
      return this.to
    }
  }
}
</script>
