<template>
  <div
    class="split-layout-right column is-two-fifths-desktop
      is-three-fifths-widescreen is-hidden-mobile-only is-hidden-tablet-only"
  >
    <logo />
    <img
      src="../assets/login/artwork.svg"
      alt="Relevant"
      class="image"
    >
    <img
      src="../assets/login/phrase.svg"
      alt="Relevant"
      class="phrase"
    >
  </div>
</template>

<script>
import Logo from './Logo.vue'

export default {
  name: 'SplitLayoutRight',
  components: {
    Logo
  }
}
</script>

<style lang="scss" scoped>
  .split-layout-right {
    flex-direction: column;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: linear-gradient(180deg, #2924BE 0%, #0A6CFF 100%);
    min-height: 100vh;

    @include respond(mobile) {
      display: none;
    }

    .logo {
      margin-top: 108px;
      width: 200px;
      color: #fff;
      flex-shrink: 0;
    }

    .image {
      margin-top: 79px;
      flex-shrink: 1;
      height: calc(100vh - 390px);
      flex-shrink: 1;
      margin-bottom: 59px;
      max-width: 80%;
    }

    .phrase {
      width: 500px;
      max-width: 80%;
    }
  }
</style>
