import { axiosBase, camelcaseTransformResponse } from '../../../api/axios-base' // eslint-disable-line import/no-cycle

const getInitialState = () => ({
  projectIdentifier: null,
  activityIdentifier: null,
  participationsData: [],
  participationDetailsData: {},
  totalParticipationCount: null,
  page: 1,
  pageSize: 20,
  loading: true,
  loadingParticipationDetailsData: [] // Array of identifiers being loaded
})

export default {
  namespaced: true,
  state: getInitialState(),
  getters: {
    loadingParticipationDetails: (state) => (participationIdentifier) => (
      state.loadingParticipationDetailsData.includes(participationIdentifier)
    )
  },
  mutations: {
    setInitialData(state, payload) {
      Object.assign(state, payload)
    },
    setPage(state, page) {
      Object.assign(state, {
        page,
        loading: true
      })
    },
    setParticipationsData(state, data) {
      Object.assign(state, {
        participationsData: data.results,
        totalParticipationCount: data.count,
        loading: false
      })
    },
    setLoadingParticipationDetails(state, participationIdentfier) {
      state.loadingParticipationDetailsData.push(participationIdentfier)
    },
    updateParticipationsDetailsData(state, data) {
      state.participationDetailsData[data.identifier] = data
      state.loadingParticipationDetailsData = state.loadingParticipationDetailsData.filter(
        (identifier) => identifier !== data.identifier
      )
    }
  },
  actions: {
    async updateParticipationsData({ commit, state }) {
      const { projectIdentifier, activityIdentifier } = state

      const response = await axiosBase.get(
        `/projects/${projectIdentifier}/activities/${activityIdentifier}/participations/`
        + `?page=${state.page}${state.pageSize ? `&page_size=${state.pageSize}` : ''}`,
        {
          // TODO: transform request and response globally on all Axios requests
          transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
        }
      )

      commit('setParticipationsData', response.data)
    },
    async getInitialParticipationsData({ commit, dispatch }, payload) {
      commit(
        'setInitialData', { ...getInitialState(), ...payload }
      )
      dispatch('updateParticipationsData')
    },
    async getParticipationsPage({ commit, dispatch }, { page }) {
      commit('setPage', page)
      await dispatch('updateParticipationsData')
    },
    async getParticipationDetails({ commit, state }, { participationIdentifier }) {
      const { projectIdentifier, activityIdentifier } = state

      commit('setLoadingParticipationDetails', participationIdentifier)

      const response = await axiosBase.get(
        `/projects/${projectIdentifier}/activities/${activityIdentifier}/participations/${participationIdentifier}`,
        {
          // TODO: transform request and response globally on all Axios requests
          transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
        }
      )

      commit('updateParticipationsDetailsData', response.data)
    }
  }
}
