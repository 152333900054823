//
// Forum -> Forum
//
// Loads a forum thread listing data
//

import { // eslint-disable-line import/no-cycle
  axiosBase, camelcaseTransformResponse
} from '../../../api/axios-base' // eslint-disable-line import/no-cycle

const getInitialState = () => ({
  // Stores all data the project's forum threads
  totalThreadCount: null,
  threadData: null,
  singleThreadData: null
})

export default {
  strict: true,
  namespaced: true,
  state: getInitialState(),
  getters: {
    displayForum(state, getters, rootState) {
      const { participationProject: { projectData } } = rootState

      if (!projectData) return false

      return projectData.enable_forum

      // TODO: add conditional depending on available posts
      //
      // return projectData.enable_forum
      //   && (
      //     projectData.hide_forum_with_no_threads === false
      //     || (state.threadData && state.threadData.length > 0)
      //   )
    }
  },
  mutations: {
    setThreadData(state, threadData) {
      const { count, results } = threadData

      Object.assign(state, {
        totalThreadCount: count,
        threadData: results
      })
    },
    setSingleThreadData(state, singleThreadData) {
      state.singleThreadData = singleThreadData
    },
    resetState(state) {
      Object.assign(state, getInitialState())
    }
  },
  actions: {
    async getThreadData({ commit, dispatch }, { projectIdentifier, limit }) {
      try {
        const response = await axiosBase.get(
          `/projects/${projectIdentifier}/threads/${limit ? `?limit=${limit}` : ''}`,
          {
            // TODO: transform request and response globally on all Axios requests
            transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
          }
        )
        const { data } = response

        commit('setThreadData', data)
        return true
      } catch (err) {
        dispatch('alerts/triggerErrorAlert', {
          message: (
            'Ha ocurrido un error al intentar obtener la información de la consigna')
        }, { root: true })
        return false
      }
    },
    async getSingleThreadData({ commit, dispatch }, { projectIdentifier, threadIdentifier }) {
      try {
        const response = await axiosBase.get(
          `/projects/${projectIdentifier}/threads/${threadIdentifier}/`,
          {
            // TODO: transform request and response globally on all Axios requests
            transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
          }
        )

        const { data: singleThreadData } = response

        commit('setSingleThreadData', singleThreadData)
        return true
      } catch (err) {
        dispatch('alerts/triggerErrorAlert', {
          message: (
            'Ha ocurrido un error al intentar obtener la información de la consigna')
        }, { root: true })
        return false
      }
    }
  }
}
