export const formatDate = (isoDate) => {
  const largeDate = isoDate

  const shortDate = new Date(largeDate)

  return shortDate.toLocaleDateString()
}

export const parseDate = (isoDate) => new Date(isoDate)

export const formatDateVerbose = (isoDate) => {
  const date = parseDate(isoDate)

  return `${new Intl.DateTimeFormat(
    'es', { dateStyle: 'long', timeStyle: 'short' }
  ).format(date).replace(/ de [-\s/]?\d{4}/, '')} hs`
}

export const formatActivityDates = (startDate, endDate) => {
  let formattedDate = ''

  const now = new Date()

  const sd = parseDate(startDate)
  const ed = parseDate(endDate)

  if (sd && sd > now) {
    formattedDate = 'Comienza el '
    formattedDate += formatDateVerbose(sd)
  } else if (ed && ed > now) {
    formattedDate = 'Hasta el '
    formattedDate += formatDateVerbose(ed)
  } else {
    return 'Libre'
  }

  return formattedDate
}

export const timeagoLocaleES = (number, index) => [
  ['justo ahora', 'en un rato'],
  ['hace %s segundos', 'en %s segundos'],
  ['hace 1 minuto', 'en 1 minuto'],
  ['hace %s minutos', 'en %s minutos'],
  ['hace 1 hora', 'en 1 hora'],
  ['hace %s horas', 'en %s horas'],
  ['hace 1 día', 'en 1 día'],
  ['hace %s días', 'en %s días'],
  ['hace 1 semana', 'en 1 semana'],
  ['hace %s semanas', 'en %s semanas'],
  ['hace 1 mes', 'en 1 mes'],
  ['hace %s meses', 'en %s meses'],
  ['hace 1 año', 'en 1 año'],
  ['hace %s años', 'en %s años']
][index]
