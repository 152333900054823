import { axiosBase } from '../../api/axios-base' // eslint-disable-line import/no-cycle

const getInitialState = () => ({
  networkError: false,
  tokenError: false,
  loading: false,
  submitted: false,
  success: false
})

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    networkError(state) {
      Object.assign(state, {
        loading: false,
        networkError: true
      })
    },
    tokenError(state) {
      Object.assign(state, {
        loading: false,
        tokenError: true
      })
    },
    loading(state) {
      Object.assign(state, {
        loading: true,
        networkError: false
      })
    },
    success(state) {
      Object.assign(state, {
        loading: false,
        success: true
      })
    },
    submitted(state) {
      state.submitted = true
    },
    resetState(state) {
      Object.assign(state, getInitialState())
    }
  },
  actions: {
    confirmPasswordReset(context, {
      uid,
      token,
      password
    }) {
      context.commit('loading')

      return new Promise((resolve) => {
        axiosBase.post('/auth/users/reset_password_confirm/', {
          uid,
          token,
          new_password: password
        }).then(
          () => {
            context.commit('success')
            resolve()
          }
        ).catch((error) => {
          if (error.response && error.response.status === 400) {
            // Invalid token
            context.commit('tokenError')
            resolve()
          } else {
            // Request has failed because of network issues
            context.commit('networkError')
            resolve()
          }
        })
      })
    }
  }
}
