import Vue from 'vue'
import Vuex from 'vuex'
import { axiosBase } from '../api/axios-base' // eslint-disable-line import/no-cycle

import alerts from './modules/alerts'
import staffActivities from './modules/staff/activities' // eslint-disable-line import/no-cycle
import staffParticipants from './modules/staff/participants' // eslint-disable-line import/no-cycle
import staffActivityParticipations from './modules/staff/activityParticipations' // eslint-disable-line import/no-cycle
import Invitations from './modules/invitations' // eslint-disable-line import/no-cycle
import Projects from './modules/projects' // eslint-disable-line import/no-cycle
import Users from './modules/users' // eslint-disable-line import/no-cycle
import dataExports from './modules/staff/data-exports' // eslint-disable-line import/no-cycle
import comments from './modules/comments/comments' // eslint-disable-line import/no-cycle
import interactionsInteractions from './modules/interactions/interactions' // eslint-disable-line import/no-cycle
import forum from './modules/forum/forum' // eslint-disable-line import/no-cycle
import participationActivity from './modules/participation/activity' // eslint-disable-line import/no-cycle
import participationForm from './modules/participation/form' // eslint-disable-line import/no-cycle
import participationOpenQuestionnaire from './modules/participation/open-questionnaire' // eslint-disable-line
import participationParticipant from './modules/participation/participant' // eslint-disable-line import/no-cycle
import participationParticipation from './modules/participation/participation' // eslint-disable-line import/no-cycle
import participationProject from './modules/participation/project' // eslint-disable-line import/no-cycle
import participationTaskSES from './modules/participation/taskSES' // eslint-disable-line import/no-cycle
import PasswordReset from './modules/password-reset' // eslint-disable-line import/no-cycle
import PasswordResetConfirmation from './modules/password-reset-confirmation' // eslint-disable-line import/no-cycle
import Registration from './modules/registration' // eslint-disable-line import/no-cycle
import summary from './modules/summary' // eslint-disable-line import/no-cycle
import Ui from './modules/ui'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    staffActivities,
    staffParticipants,
    staffActivityParticipations,
    alerts,
    Invitations,
    comments,
    dataExports,
    interactionsInteractions,
    forum,
    participationActivity,
    participationForm,
    participationOpenQuestionnaire,
    participationParticipant,
    participationParticipation,
    participationProject,
    participationTaskSES,
    PasswordReset,
    PasswordResetConfirmation,
    Projects,
    Registration,
    summary,
    Ui,
    Users
  },
  state: {
    // makes sure the user is logged in even after refreshing the page
    accessToken: localStorage.getItem('accessToken') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    authenticating: false, // state variable of processing authentication
    refreshingToken: false, // state variable of processing authentication
    refreshingTokenCall: null
  },
  getters: {
    loggedIn(state) {
      return state.accessToken != null // Return TRUE/FALSE if token is not NULL
    }
  },
  mutations: {
    updateLocalStorage(state, { access, refresh }) {
      localStorage.setItem('accessToken', access)
      localStorage.setItem('refreshToken', refresh)
      state.accessToken = access
      state.refreshToken = refresh
    },
    updateAccess(state, access) {
      localStorage.setItem('accessToken', access)
      state.accessToken = access
    },
    destroyToken(state) {
      state.accessToken = null
      state.refreshToken = null
    },
    loginBegin(state) {
      state.authenticating = true
    },
    loginSuccess(state) {
      state.authenticating = false
    },
    clearProjects(state) {
      state.Projects.APIData = {}
    },
    setRefreshingTokenCall(state, refreshingTokenCall) {
      state.refreshingTokenCall = refreshingTokenCall
    },
    setRefreshingToken(state, refreshingToken) {
      state.refreshingToken = refreshingToken
    }
  },
  actions: {
    // Get a new access token on expiration
    refreshToken(context) {
      return new Promise((resolve, reject) => {
        axiosBase.post('/token/refresh/', {
          refresh: context.state.refreshToken
        }).then(
          // send the stored refresh token to the backend API
          (response) => { // if API sends back new access and refresh token update the store
            context.commit('updateAccess', response.data.access)
            resolve(response.data.access)
          }
        ).catch((err) => {
          reject(err) // error generating new access and refresh token because refresh token has expired
        })
      })
    },
    logoutUser({ commit, getters }) {
      if (getters.loggedIn) {
        return new Promise((resolve) => {
          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')
          localStorage.removeItem('userData')

          delete axiosBase.defaults.headers.common['Authorization']

          commit('clearProjects')
          commit('destroyToken')
          commit('participationActivity/resetState', null, { root: true })
          commit('participationOpenQuestionnaire/resetState', null, { root: true })
          commit('participationParticipant/resetState', null, { root: true })
          commit('participationProject/resetState', null, { root: true })
          commit('forum/resetState', null, { root: true })
          resolve()
        })
      }

      return new Promise((resolve) => {
        resolve()
      })
    },
    loginUser(context, credentials) {
      return new Promise((resolve, reject) => {
        context.commit('loginBegin') // If validation is working, set var "Autenticating" with TRUE

        axiosBase.post('/token/', {
          email: credentials.username,
          password: credentials.password
        }).then(
          // IF validation OK, save Token & Refresh in LocalStorage through the "UpdateLocalStorage" mutation
          (response) => {
            context.commit('loginSuccess') // If validation finished, set var "Autenticating" with FALSE
            // store the access and refresh token in localstorage
            context.commit('updateLocalStorage', { access: response.data.access, refresh: response.data.refresh })
            resolve()
          }
        ).catch((err) => {
          console.log(err)
          context.commit('loginSuccess')
          reject(err)
        })
      })
    }
  }
})
