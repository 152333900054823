//
// This module concentrates all SES Task participation funcionality
//
//
import Vue from 'vue'
import {
  propertiesNames, propertiesPosition, tableA, tableB, tableC, tableD, tableE, tableRIM
} from '@/components/open-questionnaire/task-ses/taskSES'

export default {
  namespaced: true,
  state: {
    response: {
      [propertiesNames.numberOfMembers]: NaN,
      [propertiesNames.membersWhoHaveIncome]: NaN,
      [propertiesNames.educationLevel]: NaN,
      [propertiesNames.medicalCoverage]: NaN,
      [propertiesNames.workStatus]: NaN,
      [propertiesNames.workType]: NaN,
      [propertiesNames.workCategory]: NaN,
      [propertiesNames.workQualification]: NaN,
      [propertiesNames.numberOfEmployees]: NaN,
      [propertiesNames.workIntensity]: NaN
    },
    currentQuestionCode: propertiesNames.numberOfMembers,
    validationError: false
  },
  getters: {
    rim(state) {
      if (state.response.numberOfMembers && state.response.membersWhoHaveIncome) {
        const membersWhoHaveIncome = state.response.membersWhoHaveIncome > 8 ? 8 : state.response.membersWhoHaveIncome

        return tableRIM[state.response.numberOfMembers][membersWhoHaveIncome]
      }

      return NaN
    },

    isResponseValid(state) {
      if (state.response[state.currentQuestionCode] !== undefined) {
        return !Number.isNaN(state.response[state.currentQuestionCode])
      }
      return false
    },

    nextQuestionCode(state) {
      let nextQuestionCode = state.currentQuestionCode
      const value = state.response[state.currentQuestionCode]

      if (state.currentQuestionCode === propertiesNames.numberOfMembers) {
        nextQuestionCode = propertiesNames.membersWhoHaveIncome
      } else if (state.currentQuestionCode === propertiesNames.membersWhoHaveIncome) {
        nextQuestionCode = propertiesNames.educationLevel
      } else if (state.currentQuestionCode === propertiesNames.educationLevel) {
        nextQuestionCode = propertiesNames.medicalCoverage
      } else if (state.currentQuestionCode === propertiesNames.medicalCoverage) {
        nextQuestionCode = propertiesNames.workStatus
      } else if (state.currentQuestionCode === propertiesNames.workStatus) {
        if (value === 1 || value === 2) {
          nextQuestionCode = propertiesNames.workType
        }
      } else if (state.currentQuestionCode === propertiesNames.workType) {
        if (state.response.workStatus === 2 || value === 2) {
          nextQuestionCode = propertiesNames.workCategory
        } else if (value === 1) {
          nextQuestionCode = propertiesNames.numberOfEmployees
        } else if (value === 3) {
          nextQuestionCode = propertiesNames.workQualification
        }
      } else if (state.currentQuestionCode === propertiesNames.workCategory) {
        nextQuestionCode = propertiesNames.workQualification
      } else if (state.currentQuestionCode === propertiesNames.workQualification) {
        if (state.response.workStatus !== 2) {
          if (state.response.workType === 2) {
            nextQuestionCode = propertiesNames.numberOfEmployees
          } else {
            nextQuestionCode = propertiesNames.workIntensity
          }
        }
      }

      return nextQuestionCode
    },

    prevQuestionCode(state) {
      let prevQuestionCode = state.currentQuestionCode

      if (state.currentQuestionCode === propertiesNames.membersWhoHaveIncome) {
        prevQuestionCode = propertiesNames.numberOfMembers
      } else if (state.currentQuestionCode === propertiesNames.educationLevel) {
        prevQuestionCode = propertiesNames.membersWhoHaveIncome
      } else if (state.currentQuestionCode === propertiesNames.medicalCoverage) {
        prevQuestionCode = propertiesNames.educationLevel
      } else if (state.currentQuestionCode === propertiesNames.workStatus) {
        prevQuestionCode = propertiesNames.medicalCoverage
      } else if (state.currentQuestionCode === propertiesNames.workType) {
        prevQuestionCode = propertiesNames.workStatus
      } else if (state.currentQuestionCode === propertiesNames.workCategory) {
        prevQuestionCode = propertiesNames.workType
      } else if (state.currentQuestionCode === propertiesNames.numberOfEmployees) {
        const { workType } = state.response
        if (workType === 1) {
          prevQuestionCode = propertiesNames.workType
        } else {
          prevQuestionCode = propertiesNames.workQualification
        }
      } else if (state.currentQuestionCode === propertiesNames.workQualification) {
        const { workType } = state.response
        if (workType === 3) {
          prevQuestionCode = propertiesNames.workType
        } else {
          prevQuestionCode = propertiesNames.workCategory
        }
      } else if (state.currentQuestionCode === propertiesNames.workIntensity) {
        prevQuestionCode = propertiesNames.workQualification
      }

      return prevQuestionCode
    },

    SESValue(state, getters) {
      const medicalCoverage = state.response.medicalCoverage === 3 ? 2 : state.response.medicalCoverage
      const numberOfEmployees = state.response.numberOfEmployees === 5 ? 1 : state.response.numberOfEmployees

      if (state.response.workStatus === 1) {
        if (state.response.workType === 1) {
          console.log('Table B')
          if (state.response.educationLevel && getters.rim && numberOfEmployees) {
            return tableB[state.response.educationLevel][getters.rim][numberOfEmployees]
          }
        } else if (state.response.workType === 2) {
          console.log('Table A')
          if (state.response.educationLevel && getters.rim && medicalCoverage && state.response.workCategory) {
            if (state.response.workCategory === 3) {
              if (state.response.workQualification) {
                const firstPart = tableA[medicalCoverage][state.response.educationLevel]
                return firstPart[getters.rim][state.response.workCategory][state.response.workQualification]
              }
            } else if (numberOfEmployees) {
              const firstPart = tableA[medicalCoverage][state.response.educationLevel]
              return firstPart[getters.rim][state.response.workCategory][numberOfEmployees]
            }
          }
        } else if (state.response.workType === 3) {
          console.log('Table C')
          if (state.response.educationLevel && getters.rim && medicalCoverage
            && state.response.workQualification && state.response.workIntensity) {
            const firstPart = tableC[medicalCoverage][state.response.educationLevel]
            return firstPart[getters.rim][state.response.workQualification][state.response.workIntensity]
          }
        }
      } else if (state.response.workStatus === 2) {
        console.log('Table D')
        if (state.response.educationLevel && getters.rim && state.response.workType) {
          if (state.response.workType === 3) {
            if (state.response.workQualification) {
              const firstPart = tableD[state.response.educationLevel][getters.rim]
              return firstPart[state.response.workType][state.response.workQualification]
            }
          } else if (state.response.workType === 2) {
            if (state.response.workCategory) {
              const firstPart = tableD[state.response.educationLevel][getters.rim]
              return firstPart[state.response.workType][state.response.workCategory]
            }
          } else {
            return tableD[state.response.educationLevel][getters.rim][state.response.workType]
          }
        }
      } else if (state.response.workStatus >= 3) {
        console.log('Table E')
        if (state.response.educationLevel && getters.rim && medicalCoverage) {
          if (state.response.workStatus === 3) {
            return tableE[state.response.educationLevel][getters.rim][state.response.workStatus]
          }

          return tableE[state.response.educationLevel][getters.rim][state.response.workStatus][medicalCoverage]
        }
      }

      return NaN
    }
  },
  mutations: {
    setValue(state, { propertyName, intValue }) {
      Vue.set(state.response, propertyName, intValue)
    },

    setValidationError(state, value) {
      state.validationError = value
    },

    setResponse(state, value) {
      state.response = value
    },

    setCurrentQuestionCode(state, value) {
      state.currentQuestionCode = value
    }
  },
  actions: {
    valueChanged({ commit }, { propertyName, value }) {
      commit('setValidationError', false)

      const intValue = parseInt(value, 10)
      commit('setValue', { propertyName, intValue })
    },

    setInitialResponse({ commit, rootGetters }) {
      const response = rootGetters['participationParticipation/currentTaskResponse']

      const values = response ? response.value.split(',').map(Number) : []

      const value = {
        [propertiesNames.numberOfMembers]: values[propertiesPosition.numberOfMembers],
        [propertiesNames.membersWhoHaveIncome]: values[propertiesPosition.membersWhoHaveIncome],
        [propertiesNames.educationLevel]: values[propertiesPosition.educationLevel],
        [propertiesNames.medicalCoverage]: values[propertiesPosition.medicalCoverage],
        [propertiesNames.workStatus]: values[propertiesPosition.workStatus],
        [propertiesNames.workType]: values[propertiesPosition.workType],
        [propertiesNames.workCategory]: values[propertiesPosition.workCategory],
        [propertiesNames.workQualification]: values[propertiesPosition.workQualification],
        [propertiesNames.numberOfEmployees]: values[propertiesPosition.numberOfEmployees],
        [propertiesNames.workIntensity]: values[propertiesPosition.workIntensity]
      }

      commit('setResponse', value)
    },

    previousStep({
      commit, dispatch, getters, state
    }) {
      const { prevQuestionCode } = getters

      if (prevQuestionCode === state.currentQuestionCode) {
        dispatch('participationParticipation/previousStep', null, { root: true })
      } else {
        dispatch('setInitialResponse')
        commit('setCurrentQuestionCode', prevQuestionCode)
      }
    },

    submitForm({
      commit, getters, state, rootGetters
    }) {
      if (getters.isResponseValid) {
        commit('setValidationError', false)

        const values = []
        values[propertiesPosition.numberOfMembers] = state.response.numberOfMembers
        values[propertiesPosition.membersWhoHaveIncome] = state.response.membersWhoHaveIncome
        values[propertiesPosition.educationLevel] = state.response.educationLevel
        values[propertiesPosition.medicalCoverage] = state.response.medicalCoverage
        values[propertiesPosition.workStatus] = state.response.workStatus
        values[propertiesPosition.workType] = state.response.workType
        values[propertiesPosition.workCategory] = state.response.workCategory
        values[propertiesPosition.workQualification] = state.response.workQualification
        values[propertiesPosition.numberOfEmployees] = state.response.numberOfEmployees
        values[propertiesPosition.workIntensity] = state.response.workIntensity

        const form = {
          optionValue: '',
          optionsValue: [],
          optionsResponses: [],
          value: values.join(',')
        }

        const currentTaskData = rootGetters['participationOpenQuestionnaire/currentTaskData']

        if (getters.SESValue !== undefined && !Number.isNaN(getters.SESValue)) {
          const option = currentTaskData.options.find((o) => o.order === getters.SESValue)
          form.optionValue = option.id
          console.log('option', option.description)
        }

        let moveForward = false

        if (getters.currentQuestionCode !== getters.nextQuestionCode) {
          commit('setCurrentQuestionCode', getters.nextQuestionCode)
        } else {
          moveForward = true
        }

        console.log(form, moveForward)
        // this.$root.$emit('updateParticipation', { form, moveForward })
      } else {
        // this.$store.commit('participationTaskSES/setErrorMsg', true)
      }
    }
  }
}
