import { axiosBase } from '../../api/axios-base' // eslint-disable-line import/no-cycle
import { isValidInvitationCode } from '../../api/utils'

// Check for invitation code
const urlParams = new URLSearchParams(window.location.search)
const URLInvitationCode = urlParams.get('i')

const getInitialState = (loading) => ({
  admin: null, // Is the user Admin or Participant?
  invitationCode: null, // Selected invitation code
  email: null, // Invited email
  projectName: null,
  invitationValid: null, // Is the currently selected invitation valid? (not used or deleted)
  networkError: false,
  loading: loading || false
})

export default {
  namespaced: true,
  state: getInitialState(URLInvitationCode && isValidInvitationCode(URLInvitationCode)),
  mutations: {
    networkError(state) {
      Object.assign(state, {
        loading: false,
        networkError: true
      })
    },
    loading(state, invitationCode) {
      Object.assign(state, {
        invitationCode,
        loading: true,
        networkError: false
      })
    },
    invitationValid(state) {
      Object.assign(state, {
        loading: false,
        success: true,
        invitationValid: true
      })
    },
    resetState(state) {
      Object.assign(state, getInitialState())
    },
    success(state, data) {
      Object.assign(state, {
        email: data.email,
        admin: data.project_admin,
        projectName: data.project.name,
        invitationValid: !data.used_on,
        loading: false,
        networkError: false
      })
    }
  },
  actions: {
    setInvitationCode(context, invitationCode) {
      if (context.state.invitationCode && context.state.invitationCode === invitationCode) {
        return Promise((resolve) => { resolve() })
      }

      context.commit('loading', invitationCode)

      return new Promise((resolve) => {
        axiosBase.get(`/invitations/${invitationCode}/`).then(
          (response) => {
            context.commit('success', response.data)
            resolve(response.data)
          }
        ).catch((err) => {
          context.commit('networkError')
          resolve(err)
        })
      })
    }
  }
}
