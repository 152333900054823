import { axiosBase } from '../../api/axios-base' // eslint-disable-line import/no-cycle

const getInitialState = () => ({
  networkError: false,
  loading: false,
  submitted: false,
  success: false
})

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    networkError(state) {
      Object.assign(state, {
        loading: false,
        networkError: true
      })
    },
    loading(state) {
      Object.assign(state, {
        loading: true,
        networkError: false
      })
    },
    success(state) {
      Object.assign(state, {
        loading: false,
        success: true
      })
    },
    submitted(state) {
      state.submitted = true
    },
    resetState(state) {
      Object.assign(state, getInitialState())
    }
  },
  actions: {
    requestPasswordResetEmail(context, { email }) {
      context.commit('loading')

      return new Promise((resolve) => {
        axiosBase.post('/auth/users/reset_password/', { email }).then(
          (response) => {
            context.commit('success')
            resolve(response.data)
          }
        ).catch((err) => {
          context.commit('networkError')
          resolve(err)
        })
      })
    }
  }
}
