import { axiosBase, camelcaseTransformResponse } from '../../api/axios-base' // eslint-disable-line import/no-cycle

export default {
  strict: true,
  namespaced: true,
  state: {
    activityData: {},
    activitySummary: {}
  },
  mutations: {
    setActivity(state, data) {
      state.activityData = data
    },

    setActivitySummary(state, data) {
      state.activitySummary = data
    }
  },
  actions: {
    async getActivity({ commit, dispatch }, { projectIdentifier, activityIdentifier }) {
      try {
        const response = await axiosBase.get(`/projects/${projectIdentifier}/activities/${activityIdentifier}/`,
          {
            // TODO: transform request and response globally on all Axios requests
            transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
          })

        commit('setActivity', response.data)
        return true
      } catch (err) {
        dispatch('alerts/triggerErrorAlert', {
          message: (
            'Ha ocurrido un error al intentar obtener la información de la actividad')
        }, { root: true })
        return false
      }
    },

    async getSummary({ commit, dispatch }, { projectIdentifier, activityIdentifier }) {
      try {
        const response = await axiosBase.get(`/projects/${projectIdentifier}/activities/${activityIdentifier}/report/`)

        commit('setActivitySummary', response.data)
        return true
      } catch (err) {
        dispatch('alerts/triggerErrorAlert', {
          message: (
            'Ha ocurrido un error al intentar obtener el reporte de la actividad')
        }, { root: true })
        return false
      }
    }
  }
}
