//
// This module concentrates all participation form funcionality
//
// It is used on the whole process of a participant user filling a form.
//
import Vue from 'vue'

import sharedConstants from '@/shared/constants.json'

const defaultResponseMediaFiles = [null, null, null, null, null, null, null, null, null, null]

const getDefaultFormData = () => ({
  // Pre-fill responseMediaFiles to have enough space for skipped indexes due to separation
  // between UI images
  //
  // TODO: remove pre-filling
  responseMediaFiles: [...defaultResponseMediaFiles],
  options: [],
  optionValues: [],
  value: ''
})

export default {
  strict: true,
  namespaced: true,
  state: {
    formData: null,
    modified: false
  },
  getters: {
    customOptionValueNotProvided(state, getters, rootState, rootGetters) {
      const currentTaskData = rootGetters['participationOpenQuestionnaire/currentTaskData']
      const { formData } = state

      // On custom options (simple or multiple), a detailed answer is always required
      if (
        [
          sharedConstants.TASK_TYPE.SIMPLE_CHOICE,
          sharedConstants.TASK_TYPE.MULTIPLE_CHOICE
        ].includes(currentTaskData.taskType) && formData.options.includes('custom') && !formData.value
      ) {
        return true
      }

      return false
    },
    formDataIsValid(state, getters, rootState, rootGetters) {
      const currentTaskData = rootGetters['participationOpenQuestionnaire/currentTaskData']
      const { formData } = state

      if (!currentTaskData) {
        return false
      }

      // Returns true if the form's data is valid
      const { required: currentTaskRequired } = currentTaskData

      if (getters['customOptionValueNotProvided']) {
        return false
      }

      if (currentTaskRequired) {
        if (
          rootGetters['participationOpenQuestionnaire/currentTaskIsSimpleChoice']
          || rootGetters['participationOpenQuestionnaire/currentTaskIsNPS']
          || rootGetters['participationOpenQuestionnaire/currentTaskIsMultipleChoice']
        ) {
          return formData.options.length > 0
        }

        if (rootGetters['participationOpenQuestionnaire/currentTaskIsRating']) {
          return getters.isAllOptionsResponsesAnswered
        }

        if (rootGetters['participationOpenQuestionnaire/currentTaskIsOpenQuestion']) {
          const { responseMediaFiles, value } = formData

          const val = value !== undefined ? value.trim() : ''

          const minCharactersCountValidation = !currentTaskData.responseMinCharacterCount
            || val.length >= currentTaskData.responseMinCharacterCount

          return (val !== '' && minCharactersCountValidation)
            || responseMediaFiles.filter((mediaFile) => mediaFile !== null && mediaFile.mediaFile).length > 0
        }
      }

      return true
    },

    isAllOptionsResponsesAnswered(state) {
      return state.formData && state.formData.optionValues.filter((item) => item.integerValue === null).length === 0
    },

    displayErrorMessage(state, getters) {
      return state.modified && !getters.formDataIsValid
    },

    simpleChoiceSelectedOptionValue(state) {
      if (state.formData && state.formData.options.length > 0) {
        return state.formData.options[0]
      }

      return null
    },
    getFormOptionResponseData: (state) => (optionResponseIdentifier) => state.formData.optionValues.find(
      (optionResponse) => optionResponse.optionIdentifier === optionResponseIdentifier
    )
  },
  mutations: {
    updateFormData(state, formData) {
      const newMediaFilesData = [...defaultResponseMediaFiles]

      if (state.formData) {
        // Updates the whole formData with new values, preserving the spacing between indices
        // TODO: only execute when necessary
        formData.responseMediaFiles.forEach((mediaFile) => {
          if (mediaFile !== null) {
            const index = state.formData.responseMediaFiles.findIndex(
              (file) => file !== null && file.publicId === mediaFile.mediaFile.publicId
            )

            if (index === -1) {
              const emptyIndex = newMediaFilesData.findIndex((fileData) => fileData === null)

              newMediaFilesData[emptyIndex] = { ...mediaFile }
            } else {
              newMediaFilesData[index] = { ...mediaFile }
            }
          }
        })
      } else if (formData.responseMediaFiles) {
        formData.responseMediaFiles.forEach((mediaFile, index) => {
          newMediaFilesData[index] = mediaFile
        })
      }

      state.formData = {
        ...formData,
        responseMediaFiles: newMediaFilesData
      }
    },
    fillDefaultFormData(state) {
      Object.assign(
        state,
        {
          formData: getDefaultFormData(),
          modified: false
        }
      )
    },
    removeMedia(state, { index }) {
      const newMediaFiles = [...state.formData.responseMediaFiles]
      newMediaFiles[index] = null

      state.formData.responseMediaFiles = newMediaFiles
    },
    addOptionResponse(state, value) {
      state.formData.optionValues.push(value)
    },
    updateResponseMediaFileDescription(state, { index, value }) {
      Vue.set(
        state.formData.responseMediaFiles,
        index,
        { ...state.formData.responseMediaFiles[index], description: value }
      )
    },
    updateResponseMediaFileMedia(state, { index, value }) {
      const description = (
        state.formData.responseMediaFiles[index] && state.formData.responseMediaFiles[index].description
          ? state.formData.responseMediaFiles[index].description : ''
      )

      const newMediaFileData = {
        description,
        mediaFile: value
      }

      Vue.set(state.formData.responseMediaFiles, index, newMediaFileData)
    },
    updateResponseMediaFiles(state, value) {
      state.formData.responseMediaFiles = value
    },
    updateSimpleChoiceValue(state, value) {
      const formDataUpdate = {
        options: value !== null ? [value] : []
      }

      if (state.formData.value && value !== 'custom') {
        formDataUpdate.value = ''
      }

      state.formData = { ...state.formData, ...formDataUpdate }
    },
    updateOptions(state, value) {
      const formDataUpdate = {
        options: [...value]
      }

      if (!formDataUpdate.options.includes('custom')) {
        formDataUpdate.value = ''
      }

      state.formData = { ...state.formData, ...formDataUpdate }
    },
    updateOptionResponseIntegerValue(state, { optionIdentifier, value }) {
      const optionResponse = state.formData.optionValues.find(
        (op) => op.optionIdentifier === optionIdentifier
      )

      if (optionResponse) {
        optionResponse.integerValue = value
      } else {
        state.formData.optionValues = [
          ...state.formData.optionValues,
          {
            optionIdentifier,
            integerValue: value,
            value: ''
          }
        ]
      }
    },
    updateValue(state, value) {
      const { formData, modified } = state

      Object.assign(state, {
        formData: {
          ...formData,
          value
        },
        modified: (modified || value.trim() !== '')
      })
    }
  },
  actions: {
    updateFormDataFromState({ commit, rootGetters }) {
      const currentTaskResponse = rootGetters['participationParticipation/currentTaskResponse']

      if (!currentTaskResponse) {
        commit('fillDefaultFormData')
      } else {
        const newFormData = { ...currentTaskResponse }

        if (
          (
            rootGetters['participationOpenQuestionnaire/currentTaskIsSimpleChoice']
            || rootGetters['participationOpenQuestionnaire/currentTaskIsMultipleChoice']
          ) && (newFormData && newFormData.value)
        ) {
          newFormData.options = [...newFormData.options, 'custom']
        }

        commit('updateFormData', newFormData)
      }
    },

    fillFormOptionsResponses({ commit, rootGetters }) {
      const currentTaskResponse = rootGetters['participationParticipation/currentTaskResponse']

      currentTaskResponse.optionValues.forEach((item) => {
        commit('updateOptionResponse', { option: item.option, value: item.integerValue })
      })
    },

    fillDefaultOptionsResponses({ commit, rootGetters }) {
      const currentTaskData = rootGetters['participationOpenQuestionnaire/currentTaskData']

      currentTaskData.options.forEach((item) => {
        commit('addOptionResponse', {
          integerValue: null,
          option: item.id,
          value: ''
        })
      })
    },

    fillDefaultMediaFiles({ commit, rootGetters }) {
      const currentTaskData = rootGetters['participationOpenQuestionnaire/currentTaskData']

      const responseMediaFiles = []
      for (let i = 0; i < currentTaskData.responseMediaLimit; i += 1) {
        responseMediaFiles.push({
          mediaFile: null,
          description: ''
        })
      }

      commit('updateResponseMediaFiles', responseMediaFiles)
    }
  }
}
