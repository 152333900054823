// export default {
//   es: {
//     name: 'Español',
//     load: () => import('./es.json')
//   },
//   en: {
//     name: 'Inglés',
//     load: () => import('./en.json')
//   }
// }
export default {
  es: {
    name: 'Español',
    load: () => import('./es.json')
  }
}
