//
// This module concentrates all participation funcionality
//
// It is used on the whole process of a participant user accessing a project,
// accepting terms and conditions and filling in activities.
//
import sharedConstants from '@/shared/constants.json'
import offerwiseURL from '@/components/participation/offerwiseURL'
import { axiosBase, camelcaseTransformRequest, camelcaseTransformResponse } from '../../../api/axios-base' // eslint-disable-line import/no-cycle

const initialState = {
  participationData: null, // The current Participation's data
  redirecting: false, // Redirecting to Offerwise
  updateInProgress: false // An API request to create or update a Response is in progress
}

export default {
  namespaced: true,
  state: { ...initialState },
  getters: {
    // Defines which is the current route that corresponds to the participation's status and progress
    taskResponses: (state) => (state.participationData ? state.participationData.taskResponses : []),
    participationComplete: (state) => (
      state.participationData
      && state.participationData.status === sharedConstants['PARTICIPATION_STATUS']['COMPLETE']
    ),
    participationDisqualified: (state) => (
      state.participationData
      && state.participationData.status === sharedConstants['PARTICIPATION_STATUS']['DISQUALIFIED']
    ),
    redirectToOfferwise: (state, getters, rootState) => {
      if (
        !rootState.participationProject.projectData
        || !rootState.participationParticipant.participantData
      ) return false

      const { enable_offerwise_panels: enableOfferwisePanels } = rootState.participationProject.projectData
      const { owid } = rootState.participationParticipant.participantData

      return owid && enableOfferwisePanels
    },
    currentTaskResponse: (state, getters, rootState, rootGetters) => {
      const currentTaskData = rootGetters['participationOpenQuestionnaire/currentTaskData']

      if (currentTaskData) {
        return getters.taskResponses.find(
          (item) => item.taskIdentifier === currentTaskData.identifier
        )
      }

      return null
    }
  },
  mutations: {
    addResponseToParticipation(state, response) {
      state.participationData.taskResponses.push(response)
    },
    setParticipationData(state, participationData) {
      state.participationData = { ...participationData }
    },
    setParticipationStatus(state, value) {
      state.participationData.status = value
    },
    setRedirecting(state, value) {
      state.redirecting = value
    },
    setUpdateInProgress(state, value) {
      state.updateInProgress = value
    },
    updateResponseData(state, responseData) {
      // Finds a response by its identifier (as a responseData property) and updates it's data
      const responseIndex = state.participationData.taskResponses.findIndex(
        (response) => response.identifier === responseData.identifier
      )

      state.participationData.taskResponses[responseIndex] = {
        ...responseData
      }
    },
    resetState(state) {
      Object.assign(state, initialState)
    }
  },
  actions: {
    setParticipationDataAndTask({ commit, dispatch }, { participationData, taskData }) {
      // To begin participating, updates the Participation data, activates the first Task
      // and loads the corresponding form
      commit('setParticipationData', participationData)
      commit(
        'participationOpenQuestionnaire/activateTask',
        { taskData, resetBreadcrumbs: true },
        { root: true }
      )
      dispatch('participationForm/updateFormDataFromState', null, { root: true })
    },
    async startParticipatingInActivity({
      dispatch, rootGetters
    }) {
      // Gets/creates participation, marks it as IN_PROGRESS and moves to responding to the first task
      try {
        const projectIdentifier = rootGetters['participationProject/projectIdentifier']
        const activityIdentifier = rootGetters['participationActivity/activityIdentifier']
        const credentialsQueryString = rootGetters['participationParticipant/credentialsQueryString']

        const response = await axiosBase.post(
          `/projects/${projectIdentifier}/activities/${activityIdentifier}/participations/start-participating/
${credentialsQueryString}`,
          {},
          {
            // TODO: transform request and response globally on all Axios requests
            transformRequest: [camelcaseTransformRequest, ...axiosBase.defaults.transformRequest],
            transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
          }
        )
        const { data: { firstTask: taskData, participation: participationData } } = response

        // Update the participant's data from the response
        dispatch('setParticipationDataAndTask', { participationData, taskData })
      } catch (exception) {
        console.log(exception)
        dispatch('alerts/triggerErrorAlert', {
          message: 'Ha ocurrido un error, por favor recarga la página e intenta nuevamente'
        }, { root: true })
      }
    },
    setParticipationStatus({ commit, getters, rootState }, newStatus) {
      // Updates the Participation status for a new one (COMPLETE or DISQUALIFIED)
      //
      // Redirects back to Offerwise if necessary
      const { redirectToOfferwise } = getters
      const { owid } = rootState.participationParticipant.participantData

      if (redirectToOfferwise) {
        commit('setRedirecting', true)

        if (newStatus === sharedConstants.PARTICIPATION_STATUS.COMPLETE) {
          window.location.href = offerwiseURL(`/processsurvey.php?status=complete&owid=${owid}`)
        } else if (newStatus === sharedConstants.PARTICIPATION_STATUS.DISQUALIFIED) {
          window.location.href = offerwiseURL(`/processsurvey.php?status=screened&owid=${owid}`)
        }
      } else {
        commit('setParticipationStatus', newStatus)
      }
    },
    goToPreviousStep({ commit, dispatch }) {
      // Go to previous Task and re-generate Form
      commit('participationOpenQuestionnaire/goToPreviousStep', null, { root: true })
      dispatch('participationForm/updateFormDataFromState', null, { root: true })
    }
  }
}
