//
// Participation -> Project
//
// This module fetches and update the basic project data for the user participation process
//
import { get } from 'lodash'

import sharedConstants from '@/shared/constants.json'
import { axiosBase } from '../../../api/axios-base' // eslint-disable-line import/no-cycle

const getInitialState = () => ({
  // Stores all data from the currently selected project
  projectData: null,
  projectNotFound: false
})

export default {
  namespaced: true,
  state: getInitialState(),
  mutations: {
    setProjectData(state, projectData) {
      Object.assign(state, {
        projectData,
        projectNotFound: false
      })
    },
    setProjectNotFound(state) {
      state.projectNotFound = true
    },
    resetState(state) {
      Object.assign(state, getInitialState())
    }
  },
  getters: {
    isAnonymousProject(state) {
      return (state.projectData && (
        state.projectData.identification_requirements === sharedConstants.PROJECT_IDENTIFICATION_REQUIREMENT.ANONYMOUS))
    },
    isActive: (state) => {
      const { projectData } = state

      return (
        !projectData
        || (projectData.status === sharedConstants.PROJECT_STATUS.ACTIVE && projectData.project_started)
      )
    },
    projectEnded: (state) => {
      const { projectData, projectNotFound } = state

      return !projectNotFound && (!projectData || projectData.project_ended)
    },
    projectIdentifier(state) {
      return state.projectData ? state.projectData.identifier : null
    },
    isCommunityProject(state) {
      return state.projectData && state.projectData.project_type === sharedConstants.PROJECT_TYPE.COMMUNITY
    },
    pendingActivities(state) {
      if (!state.projectData && !state.projectData.activities) {
        return null
      }

      return state.projectData.activities.filter(
        (activity) => (
          !activity.participations
          || activity.participations.length === 0
          || [
            sharedConstants.PARTICIPATION_STATUS.NOT_STARTED,
            sharedConstants.PARTICIPATION_STATUS.IN_PROGRESS
          ].includes(activity.participations[0].status)
        )
      )
    }
  },
  actions: {
    async getProjectData({ commit, dispatch, state }, { projectIdentifier, forceUpdate }) {
      // If the current project is not already loaded, go ahead and fetch it from the API

      if (forceUpdate || !state.projectData || state.projectData.identifier !== projectIdentifier) {
        // Project data hasn't been loaded or corresponds to a different project
        try {
          const response = await axiosBase.get(`/projects/${projectIdentifier}/?participation=true`)
          const { data: projectData } = response

          commit('setProjectData', projectData)

          return true
        } catch (error) {
          if (get(error, 'response.status', null) === 404) {
            commit('setProjectNotFound')
          } else {
            dispatch('alerts/triggerDataFetchErrorAlert', null, { root: true })
          }
          return false
        }
      }

      return true
    }
  }
}
