<template>
  <div class="simple-header">
    <router-link :to="{ name: 'login' }">
      <logo />
    </router-link>
  </div>
</template>

<script>
import Logo from './Logo.vue'

export default {
  name: 'TabletMobileSimpleHeader',
  components: {
    Logo
  }
}
</script>

<style lang="scss" scoped>
  .simple-header {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(#516B98, 0.16);
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      display: block;
      width: 140px;
      color: $blue;
    }
  }
</style>
