<!-- eslint-disable -->
<template>
  <svg
    viewBox="0 0 150 31"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    class="logo"
  >
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        transform="translate(-98.000000, -188.000000)"
        fill="currentColor"
      >
        <g transform="translate(98.000000, 188.000000)">
          <path d="M138.82716,25.6627159 L138.82716,11.4420748 L149.950861,11.4420748 L149.950861,7.65731455 L138.82716,7.65731455 L138.82716,3.68187427 L134.25042,3.68187427 L134.25042,7.65731455 L130.381799,7.65731455 L130.381799,11.4420748 L134.25042,11.4420748 L134.25042,25.9705759 C134.25042,27.352236 134.4579,28.5524561 134.8436,29.5747361 L141.31566,29.5747361 C139.7919,29.1741961 138.82716,27.886056 138.82716,25.6627159" />
          <path d="M22.5906396,16.4149872 L35.4411006,16.4149872 C34.7370405,12.8504469 32.2285203,11.0900868 29.2360201,11.0900868 C26.3752599,11.0900868 23.2946997,12.8944069 22.5906396,16.4149872 L22.5906396,16.4149872 Z M40.1500009,19.6716674 L22.4586196,19.6716674 C22.8984997,23.7204677 25.8473199,26.1409279 29.0599001,26.1409279 C31.4803603,26.1409279 33.9449205,25.4368678 35.4852006,22.3563076 L39.5779609,23.2364877 C37.7735007,27.813368 33.9008205,30.1016682 29.0599001,30.1016682 C23.1185797,30.1016682 17.9258393,25.5249278 17.9258393,18.6155073 C17.9258393,11.6622668 23.0747597,7.04128651 29.2360201,7.04128651 C35.0888605,7.04128651 39.9300609,11.5299668 40.1500009,18.0872873 L40.1500009,19.6716674 Z" />
          <polygon points="40.867977 29.4857101 45.4447173 29.4857101 45.4447173 0.601328044 40.867977 0.601328044" />
          <path d="M50.8275777,16.4149872 L63.6780386,16.4149872 C62.9739786,12.8504469 60.4653184,11.0900868 57.4728182,11.0900868 C54.612338,11.0900868 51.5316377,12.8944069 50.8275777,16.4149872 L50.8275777,16.4149872 Z M68.3870789,19.6716674 L50.6956977,19.6716674 C51.1355777,23.7204677 54.0842579,26.1409279 57.2966981,26.1409279 C59.7172983,26.1409279 62.1818585,25.4368678 63.7221386,22.3563076 L67.8148989,23.2364877 C66.0104388,27.813368 62.1376185,30.1016682 57.2966981,30.1016682 C51.3556577,30.1016682 46.1626373,25.5249278 46.1626373,18.6155073 C46.1626373,11.6622668 51.3116977,7.04128651 57.4728182,7.04128651 C63.3259386,7.04128651 68.1669989,11.5299668 68.3870789,18.0872873 L68.3870789,19.6716674 Z" />
          <polygon points="79.9829998 29.4856541 75.0979794 29.4856541 65.7242788 7.61331255 70.6970791 7.61331255 77.5625396 24.0285937 84.4278601 7.61331255 89.4008005 7.61331255" />
          <path d="M105.627474,18.5714633 C105.627474,13.994583 102.063073,11.0901428 98.4542931,11.0901428 C94.4495928,11.0901428 91.3249326,13.994583 91.3249326,18.5714633 C91.3249326,23.1924437 94.4495928,26.0529239 98.4542931,26.0529239 C102.063073,26.0529239 105.627474,23.1924437 105.627474,18.5714633 L105.627474,18.5714633 Z M110.204634,29.4855841 L105.627474,29.4855841 L105.627474,26.977204 C103.735374,28.9576441 101.006773,30.1017242 97.7062731,30.1017242 C91.9851727,30.1017242 86.8802123,25.4808838 86.8802123,18.5714633 C86.8802123,11.7061428 91.9851727,7.04134251 97.7062731,7.04134251 C101.006773,7.04134251 103.735374,8.18542259 105.627474,10.1658627 L105.627474,7.65734255 L110.204634,7.65734255 L110.204634,29.4855841 Z" />
          <path d="M143.268058,27.512928 C143.268058,25.6675879 144.764098,24.1716877 146.609439,24.1716877 C148.454919,24.1716877 149.950819,25.6675879 149.950819,27.512928 C149.950819,29.3582681 148.454919,30.8541682 146.609439,30.8541682 C144.764098,30.8541682 143.268058,29.3582681 143.268058,27.512928" />
          <path d="M121.096103,11.2385428 C117.966543,11.2385428 115.895382,13.618543 115.895382,17.1886832 L115.895382,29.4855841 L111.267262,29.4855841 L111.267262,16.2630032 C111.267262,10.7978228 115.773722,7.00732251 121.018683,7.00732251 L121.033523,7.00732251 C126.278483,7.00732251 130.784943,10.7978228 130.784943,16.2630032 L130.784943,29.4855841 L126.156823,29.4855841 L126.156823,17.1886832 C126.156823,13.618543 124.085523,11.2385428 120.956103,11.2385428" />
          <path d="M19.2757614,8.88958064 C19.2757614,3.56454026 15.2269611,4.26325641e-14 9.15362066,4.26325641e-14 L9.9475983e-14,4.26325641e-14 L9.9475983e-14,4.26860031 L9.15362066,4.26860031 C12.4103009,4.26860031 14.5668611,6.11702044 14.5668611,8.88958064 C14.5668611,11.6180408 12.4103009,13.466461 9.15362066,13.466461 L9.9475983e-14,13.466461 L9.9475983e-14,17.7353413 L10.5180608,29.4856821 L16.3710412,29.4856821 L5.76492042,17.7238613 L9.98984072,17.6913813 C15.5789211,17.3832413 19.2757614,13.950441 19.2757614,8.88958064" />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Logo'
}
</script>
