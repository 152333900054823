import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

import store from '../store' // eslint-disable-line import/no-cycle
import router from '../router' // eslint-disable-line import/no-cycle

// Define URL Backend API
const APIUrl = process.env.VUE_APP_API_ENDPOINT

// Define URL Base + Headers in a global constant for API calls
const axiosBase = axios.create({
  baseURL: APIUrl,
  headers: {
    'content-type': 'application/json'
  }
})

function refreshToken(storeRef) {
  if (storeRef.state.refreshingToken) {
    return storeRef.state.refreshingTokenCall
  }
  storeRef.commit('setRefreshingToken', true)
  const authenticatingCall = storeRef.dispatch('refreshToken').then(() => {
    storeRef.commit('setRefreshingToken', false)
    storeRef.commit('setRefreshingTokenCall', undefined)
    return Promise.resolve(true)
  })
  storeRef.commit('setRefreshingTokenCall', authenticatingCall)
  return authenticatingCall
}

// Set a global token for every API call
axiosBase.interceptors.request.use(
  (config) => {
    const token = store.state.accessToken
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

axiosBase.interceptors.response.use((response) => response,
  (error) => {
    const status = error.response ? error.response.status : null
    const { config } = error

    // if error response status is 401, it means the request was invalid due to expired access token
    if (status === 401) {
      if (config.url === '/token/refresh/') {
        store.dispatch('logoutUser').then(() => {
          router.push({ name: 'login' })
        })
      }

      return refreshToken(store).then(() => {
        config.headers['Authorization'] = `Bearer ${store.state.accessToken}`

        return axiosBase.request(config)
      })
    }

    return Promise.reject(error)
  })

// Transforms a camel-cased outgoing request into snake case
const camelcaseTransformRequest = (data) => {
  // When the access token has expired, and the request is repeated, the data
  // received has already been transformed and stringified
  if (typeof (data) === 'object') {
    return JSON.stringify(snakecaseKeys(data, { deep: true }))
  }

  return data
}

// Transforms an incoming snake-cased response into camel case
const camelcaseTransformResponse = (data) => camelcaseKeys(JSON.parse(data), { deep: true })

export { axiosBase, camelcaseTransformResponse, camelcaseTransformRequest }
