import sharedConstants from '@/shared/constants'

import { axiosBase, camelcaseTransformResponse } from '../../api/axios-base' // eslint-disable-line import/no-cycle

export default {
  namespaced: true,
  state: {
    projectsData: null, // received data from the backend API is stored here.
    projectData: '',
    platformTermsStatus: '',
    projectTermsStatus: ''
  },
  getters: {
    allProjects: (state) => state.projectsData,
    sortedProjects(state) {
      let projects = []

      if (Array.isArray(state.projectsData)) {
        projects = [...state.projectsData].sort((a, b) => new Date(a.finishDate) - new Date(b.finishDate))
      }

      return projects
    },
    projectData(state) {
      return state.projectData
    },
    projectNotPublished(state) {
      return state.projectData && state.projectData.status === sharedConstants.PROJECT_STATUS.NOT_PUBLISHED
    },
    isCommunityProject(state) {
      return state.projectData && state.projectData.projectType === sharedConstants.PROJECT_TYPE.COMMUNITY
    },
    projectParticipantCount(state) {
      return state.projectData ? state.projectData.participantCount : 0
    },
    currentProjectStaffUser(state, getters, rootState) {
      if (!rootState.Users.userData || !getters.projectData) return null

      return getters.projectData.staffUsers.find(
        (staffUser) => staffUser.userIdentifier === rootState.Users.userData.identifier
      )
    },
    currentProjectStaffUserIsObserver(state, getters) {
      return (
        getters.currentProjectStaffUser
        && getters.currentProjectStaffUser.role === sharedConstants.PROJECT_STAFF_USER_ROLES.OBSERVER
      )
    },
    isAnonymousProject(state) {
      return (state.projectData && (
        state.projectData.identificationRequirements === sharedConstants.PROJECT_IDENTIFICATION_REQUIREMENT.ANONYMOUS))
    }
  },
  mutations: {
    setProjectsData(state, data) {
      state.projectsData = data
    },
    setProjectData(state, data) {
      state.projectData = data
    },
    setPlatformTermStatus(state, status) {
      state.platformTermsStatus = status
    },
    setProjectTermStatus(state, status) {
      state.projectTermsStatus = status
    }
  },
  actions: {
    async getProjectsData(context) {
      const response = await axiosBase.get(
        '/projects/',
        {
          // TODO: transform request and response globally on all Axios requests
          transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
        }
      )

      context.commit('setProjectsData', response.data.results)
    },
    async getProjectData({ commit, dispatch }, { projectIdentifier, edit }) {
      try {
        const response = await axiosBase.get(
          `/projects/${projectIdentifier}/${edit ? '?edit=true' : ''}`,
          {
            // TODO: transform request and response globally on all Axios requests
            transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
          }
        )

        commit('setProjectData', response.data)
        return true
      } catch (err) {
        dispatch('alerts/triggerErrorAlert', {
          message: (
            'Ha ocurrido un error al intentar obtener la información del proyecto')
        }, { root: true })
        return false
      }
    },
    updateProjectStatus(context, data) {
      return new Promise((resolve) => {
        axiosBase.put(`/projects/${data.identifier}/`, data)
          .then((response) => {
            resolve(response)
          })
      })
    }
  }
}
