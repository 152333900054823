export default {
  namespaced: true,
  state: {
    mobileMenuOpen: false
  },
  getters: {
    isMobileMenuOpen(state) {
      return state.mobileMenuOpen
    }
  },
  mutations: {
    toggleMobileMenu(state) {
      state.mobileMenuOpen = !state.mobileMenuOpen
    }
  }
}
