//
// Participation -> Activity
//
// This module fetches the activity data
//
import sharedConstants from '@/shared/constants.json'
import { // eslint-disable-line import/no-cycle
  axiosBase, camelcaseTransformResponse
}
  from '../../../api/axios-base' // eslint-disable-line import/no-cycle

const initialState = {
  // Stores all data from the currently selected activity
  activityData: null
}

export default {
  strict: true,
  namespaced: true,
  state: { ...initialState },
  mutations: {
    setActivityData(state, activityData) {
      state.activityData = activityData
    },
    resetState(state) {
      Object.assign(state, initialState)
    },
    updateActivityParticipationData(state, participationData) {
      const newParticipationData = { ...participationData }

      if (newParticipationData.responses) {
        delete newParticipationData.responses
      }

      state.activityData.participations = [newParticipationData]
    }
  },
  getters: {
    activityIdentifier(state) {
      return state.activityData ? state.activityData.identifier : null
    },
    activityParticipations(state) {
      return state.activityData ? state.activityData.participations : []
    },
    currentParticipation(state, getters) {
      if (getters.activityParticipations.length === 1) {
        return state.activityData.participations[0]
      }

      return null
    },
    activityParticipationComplete(state, getters) {
      const { currentParticipation } = getters

      if (currentParticipation) {
        return currentParticipation.status === sharedConstants.PARTICIPATION_STATUS.COMPLETE
      }

      return false
    },
    activityParticipationDisqualified(state, getters) {
      const { currentParticipation } = getters

      if (currentParticipation) {
        return currentParticipation.status === sharedConstants.PARTICIPATION_STATUS.DISQUALIFIED
      }

      return false
    },
    activityHasDescription(state) {
      return Boolean(state.activityData && state.activityData.description)
    }
  },
  actions: {
    async getActivityData(
      { commit, dispatch, rootGetters },
      { projectIdentifier, activityIdentifier }
    ) {
      const credentialsQueryString = rootGetters['participationParticipant/credentialsQueryString']

      try {
        const response = (
          await axiosBase.get(
            `/projects/${projectIdentifier}/activities/${activityIdentifier}/${credentialsQueryString}`,
            {
              // TODO: transform request and response globally on all Axios requests
              transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
            }
          )
        )
        const { data: activityData } = response

        commit('setActivityData', activityData)
        return true
      } catch (err) {
        dispatch('alerts/triggerDataFetchErrorAlert', null, { root: true })
        return false
      }
    }
  }
}
