import Vue from 'vue'
import IdleVue from 'idle-vue'
import Buefy from 'buefy'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCalendarDay, faAngleDown, faAngleLeft, faAngleRight, faArrowUp, faEye,
  faEyeSlash, faEnvelope, faExclamationCircle, faStar
} from '@fortawesome/free-solid-svg-icons' // Declare icons to use, separated by comma.
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Fragment from 'vue-fragment'
import VueGtag from 'vue-gtag'
import VueSnip from 'vue-snip'
import Hotjar from 'vue-hotjar'
import * as Sentry from '@sentry/vue'
import { register } from 'timeago.js'
import { i18n } from 'vue-lang-router'

import sharedConstants from '@/shared/constants.json'
import App from './App.vue'
import LocalizedRouterLink from './components/LocalizedRouterLink.vue'
import router from './router'
import store from './store'

import './scss/buefy_custom.scss'

import './vee-validate'

import { timeagoLocaleES } from './dates'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

// Global CSS
import './scss/site_wide.scss'

import { isValidInvitationCode } from './api/utils'

// Register timeago locales
register('es', timeagoLocaleES)

const {
  VUE_APP_GTAG_ID, VUE_APP_SENTRY_DSN, VUE_APP_SENTRY_ENVIRONMENT
} = process.env

if (VUE_APP_GTAG_ID) {
  Vue.use(VueGtag, {
    config: { id: VUE_APP_GTAG_ID }
  })
}

if (VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: VUE_APP_SENTRY_DSN,
    attachProps: true,
    environment: VUE_APP_SENTRY_ENVIRONMENT
  })
}

// Add icons to use, separated by comma.
library.add(
  faCalendarDay, faAngleDown, faAngleLeft, faAngleRight, faArrowUp, faEye,
  faEyeSlash, faEnvelope, faExclamationCircle, faStar
)
Vue.component('vue-fontawesome', FontAwesomeIcon)

// Site-wide localized link (replaces router-link)
Vue.component('localized-router-link', LocalizedRouterLink)

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas'
})

Vue.use(Fragment.Plugin)

if (process.env.VUE_APP_APPLICATION_INSTANCE === sharedConstants.APPLICATION_INSTANCES.PRODUCTION) {
  Vue.use(Hotjar, {
    id: process.env.VUE_APP_HOTJAR_ID,
    snippetVersion: 6
  })
}

const eventsHub = new Vue()

// sets up the idle time,i.e. time left to logout the user on no activity
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  // idleTime: 720000
  idleTime: 4000
})

// Text trimming utility (with ellipsis)
Vue.use(VueSnip)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title)
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  // Process invitation code and verify it if it has the right format
  if (to.query.i && isValidInvitationCode(to.query.i)) {
    store.dispatch(
      'Invitations/setInvitationCode',
      to.query.i
    )
  }

  // if any of the routes in ./router.js has a meta named 'requiresAuth: true'
  // then check if the user is logged in before routing to this path:
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({ name: 'login' })
    } else {
      next()
    }
  } else if (to.matched.some((record) => record.meta.requiresLogged)) {
    // else if any of the routes in ./router.js has a meta named 'requiresLogged: true'
    // then check if the user is logged in; if true continue to home page else continue routing to the destination path
    // this comes to play if the user is logged in and tries to access the login/register page
    if (store.getters.loggedIn) {
      next({ name: 'proyectos' })
    } else {
      next()
    }
  } else {
    next()
  }
})

Vue.mixin({
  data() {
    return {
      get isParticipant() {
        return store.getters['Users/isParticipant']
      }
    }
  }
})

Vue.filter('capitalize', (value) => {
  if (!value) return ''
  value = value.toString()

  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('stripTags', (value) => value.replace(/<\/?[^>]+>/ig, ''))

const vue = new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App)
})

vue.$mount('#app')

export default vue
