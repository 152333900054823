import Vue from 'vue'
import LangRouter from 'vue-lang-router'
import store from '../store' // eslint-disable-line import/no-cycle
import Login from '../views/Login.vue'
import translations from '../i18n/translations'
import localizedURLs from '../i18n/localized-urls'

const EditActivity = () => import(/* webpackChunkName: "EditActivity" */ '../views/staff/EditActivity.vue')
const UserProfile = () => import('../views/UserProfile.vue')
const PasswordReset = () => import('../views/PasswordReset.vue')
const PasswordResetConfirmation = () => import('../views/PasswordResetConfirmation.vue')
const Projects = () => import('../views/Projects.vue')
const ProjectDetail = () => import('../views/ProjectDetail.vue')
const ProjectDashboard = () => import('../views/ProjectDashboard.vue')
const ProjectHome = () => import('../views/participation/ProjectHome.vue')
const ParticipationTermsAndConditions = () => import('../views/participation/TermsAndConditions.vue')
const ParticipationTermsAndConditionsDetails = () => import('../views/participation/TermsAndConditionsDetails.vue')
const Registration = () => import('../views/Registration.vue')
const NotFoundPage = () => import('../views/NotFoundPage.vue')
const ParticipationActivity = () => import('../views/participation/Activity.vue')
const SentryTest = () => import('../views/SentryTest.vue')

const ProjectInteractions = () => import('../views/interactions/ProjectInteractions.vue')
const ProjectInteractionsFiltered = () => import('../views/interactions/ProjectInteractionsFiltered.vue')
const ResponseInteractions = () => import('../views/interactions/ResponseInteractions.vue')
const TaskInteractions = () => import('../views/interactions/TaskInteractions.vue')

const ParticipantForumHome = () => import('../views/participation/ForumHome.vue')
const ParticipantForumThread = () => import('../views/participation/ForumThread.vue')

Vue.use(LangRouter, {
  defaultLanguage: 'es',
  translations,
  localizedURLs
})

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Relevant - Login',
      requiresLogged: true
    }
  },
  {
    path: '/recupera-tu-contrasena',
    name: 'password-reset',
    component: PasswordReset,
    meta: {
      title: 'Recuperar contraseña',
      requiresLogged: true
    }
  },
  {
    path: '/recupera-tu-contrasena/confirmar/:uid/:token',
    name: 'password-reset-confirm',
    component: PasswordResetConfirmation,
    meta: {
      title: 'Nueva contraseña',
      requiresLogged: true
    }
  },
  {
    path: '/registro',
    name: 'registration',
    component: Registration,
    meta: {
      title: 'Registro',
      requiresLogged: true
    }
  },
  {
    path: '/',
    name: 'proyectos',
    component: Projects,
    meta: {
      title: 'Relevant - Proyectos',
      requiresAuth: true
    }
  },
  {
    path: '/proyectos/:projectIdentifier',
    name: 'project-detail',
    component: ProjectDetail
  },
  {
    path: '/proyectos/:projectIdentifier/home',
    name: 'project-home',
    component: ProjectHome
  },
  {
    path: '/proyectos/:projectIdentifier/actividad/:activityIdentifier/editar',
    name: 'edit-activity',
    beforeEnter: (to, from, next) => {
      if (store.getters['Users/isParticipant'] === false) {
        next()
      } else {
        next({ name: 'proyectos' })
      }
    },
    component: EditActivity,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/proyectos/:projectIdentifier/foro',
    name: 'participant-forum',
    component: ParticipantForumHome,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/proyectos/:projectIdentifier/foro/:threadIdentifier',
    name: 'participant-forum-thread',
    component: ParticipantForumThread,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/proyectos/:projectIdentifier/interacciones',
    name: 'project-interactions',
    component: ProjectInteractions,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/proyectos/:projectIdentifier/interacciones-actividad/:activityIdentifier',
    name: 'project-interactions-filtered',
    component: ProjectInteractionsFiltered,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/proyectos/:projectIdentifier/interacciones/:taskIdentifier',
    name: 'task-interactions',
    component: TaskInteractions,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/proyectos/:projectIdentifier/interacciones/:taskIdentifier/:responseIdentifier',
    name: 'response-interactions',
    component: ResponseInteractions,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/proyectos/:projectIdentifier/actividad/:activityIdentifier',
    name: 'participation-activity',
    beforeEnter: (to, from, next) => {
      if (store.getters['Users/isParticipant'] === true) {
        // Reset Activity state
        if (store.state.participationActivity.activityData) {
          store.commit('participationActivity/resetState')
        }

        if (store.state.participationParticipation.participationData) {
          store.commit('participationParticipation/resetState')
        }

        if (store.state.participationParticipant.participantData) {
          store.commit('participationParticipant/resetState')
        }

        next()
      } else {
        next(false)
      }
    },
    component: ParticipationActivity,
    meta: {
      title: 'Relevant - Actividad'
    }
  },
  {
    path: '/proyectos/:projectIdentifier/terminos-y-condiciones',
    name: 'participation-terms-and-conditions',
    beforeEnter: (to, from, next) => {
      if (store.getters['Users/isParticipant'] === true) {
        next()
      } else {
        next(false)
      }
    },
    component: ParticipationTermsAndConditions,
    meta: {
      title: 'Relevant - Términos y Condiciones'
    }
  },
  {
    path: '/proyectos/:projectIdentifier/terminos-y-condiciones/:type',
    name: 'participation-terms-and-conditions-details',
    beforeEnter: (to, from, next) => {
      if (store.getters['Users/isParticipant'] === true) {
        next()
      } else {
        next(false)
      }
    },
    component: ParticipationTermsAndConditionsDetails,
    meta: {
      title: 'Relevant - Términos y Condiciones'
    }
  },
  {
    path: '/proyectos/:projectIdentifier/dashboard/resumen',
    name: 'project-dashboard-summary',
    component: ProjectDashboard,
    meta: {
      title: 'Relevant - Resumen',
      requiresAuth: true,
      activeSection: 'summary'
    }
  },
  {
    path: '/proyectos/:projectIdentifier/dashboard/foro',
    name: 'project-dashboard-forum',
    component: ProjectDashboard,
    meta: {
      title: 'Relevant - Foro',
      requiresAuth: true,
      activeSection: 'forum'
    }
  },
  {
    path: '/proyectos/:projectIdentifier/dashboard/foro/:threadIdentifier',
    name: 'project-dashboard-forum-thread',
    component: ProjectDashboard,
    meta: {
      title: 'Relevant - Tema',
      requiresAuth: true,
      activeSection: 'forum'
    }
  },
  {
    path: '/proyectos/:projectIdentifier/resumen/:activityIdentifier',
    name: 'activity-dashboard',
    component: () => import('../views/ActivityDashboard.vue'),
    meta: {
      title: 'Relevant - Actividad',
      requiresAuth: true
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "Logout" */ '../views/Logout.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mi-perfil',
    name: 'profile',
    component: UserProfile,
    meta: {
      title: 'Relevant - Mi Perfil',
      requiresAuth: true
    }
  },
  {
    path: '/sentry-test-76d59a5c7',
    name: 'sentry-test',
    component: SentryTest,
    meta: {
      title: 'Sentry test'
    }
  },
  {
    path: '*',
    name: 'not-found',
    component: NotFoundPage
  }
]

const router = new LangRouter({
  defaultLanguage: '',
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  }
})

export default router
