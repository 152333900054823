const offerwiseURL = (path) => {
  // Generates the return URL to Offerwise
  //
  // Base URL can be overridden by the VUE_APP_OFFERWISE_REDIRECT_URL setting on the environment varibles

  const { VUE_APP_OFFERWISE_REDIRECT_URL } = process.env

  return `${VUE_APP_OFFERWISE_REDIRECT_URL || 'https://www.surveytaking.com'}${path}`
}

export default offerwiseURL
