import sharedConstants from '@/shared/constants.json'
import { axiosBase, camelcaseTransformRequest, camelcaseTransformResponse } from '../../../api/axios-base' // eslint-disable-line import/no-cycle

export default {
  namespaced: true,
  state: {
    step: 1,
    activitiesData: null,
    activityData: null
  },
  getters: {
    currentStep: (state) => state.step
  },
  mutations: {
    prevStep(state) {
      state.step -= 1
    },
    nextStep(state) {
      state.step += 1
    },
    resetStep(state) {
      state.step = 1
    },
    setAllActivities(state, data) {
      state.activitiesData = data
    },
    setActivityData(state, data) {
      state.activityData = data
    }
  },

  actions: {

    prevStep(context) {
      context.commit('prevStep')
    },

    nextStep(context) {
      context.commit('nextStep')
    },

    resetStep(context) {
      context.commit('resetStep')
    },

    getAllActivities(context, projectIdentifier) {
      axiosBase.get(`/projects/${projectIdentifier}/activities/`)
        .then((response) => {
          context.commit('setAllActivities', response.data)
        })
        .catch((e) => {
          console.log(e)
        })
    },

    getActivityData({ commit }, { projectIdentifier, activityIdentifier }) {
      return new Promise((resolve, reject) => {
        axiosBase.get(`/projects/${projectIdentifier}/activities/${activityIdentifier}/?edit=true`,
          {
          // TODO: transform request and response globally on all Axios requests
            transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
          })
          .then((response) => {
            const { data } = response

            const tasks = [...data.tasks]

            // Add custom option text as an option so it's displayed in the UI
            tasks.map((task) => {
              if ([
                sharedConstants.TASK_TYPE.SIMPLE_CHOICE,
                sharedConstants.TASK_TYPE.MULTIPLE_CHOICE
              ].includes(task.taskType) && task.customOptionText) {
                task.options = [
                  ...task.options,
                  {
                    order: task.options.length + 1,
                    type: 'custom',
                    description: task.customOptionText,
                    details: ''
                  }
                ]
              }

              return task
            })

            commit('setActivityData', { ...data, tasks })
            resolve(response.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    updateActivityData(context, { projectIdentifier, activityData }) {
      const data = { ...activityData }

      // Map customOptionText as option to corresponding field
      data.tasks = data.tasks.map((task) => {
        let customOptionText = ''
        const options = []

        task.options.forEach((option) => {
          if (option.type === 'custom') {
            customOptionText = option.description
          } else {
            options.push(option)
          }
        })

        return {
          ...task,
          customOptionText,
          options
        }
      })

      return new Promise((resolve, reject) => {
        axiosBase.put(`/projects/${projectIdentifier}/activities/${activityData.identifier}/`, data,
          {
            // TODO: transform request and response globally on all Axios requests
            transformRequest: [camelcaseTransformRequest, ...axiosBase.defaults.transformRequest],
            transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
          })
          .then((response) => {
            resolve(response)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  }
}
