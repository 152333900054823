<template>
  <div id="app">
    <router-view />
    {{ messageStr }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      messageStr: '' // Hello
    }
  },
  onIdle() {
    this.messageStr = '' // zzz
  },
  onActive() {
    this.messageStr = '' // Hello
  }
}

window.cloudinary.setCloudName('relevant')
</script>

<style lang="scss" scoped>
#app {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2B2B2B;
}
</style>
