//
// Interactions -> Comments
//
// This module fetches the comments data
//
import { // eslint-disable-line import/no-cycle
  axiosBase, camelcaseTransformRequest, camelcaseTransformResponse
}
  from '../../../api/axios-base' // eslint-disable-line import/no-cycle

export default {
  namespaced: true,
  state: {
    // Stores all data for comments
    commentsData: null,
    isSavingComment: false
  },
  mutations: {
    addCommentData(state, commentData) {
      Object.assign(state, { commentsData: [commentData, ...state.commentsData], isSavingComment: false })
    },
    setCommentsData(state, commentsData) {
      state.commentsData = commentsData
    },
    setIsSavingComment(state, isSavingComment) {
      state.isSavingComment = isSavingComment
    }
  },
  actions: {
    async addComment({ commit, dispatch }, {
      content,
      commentedContentAppLabel,
      commentedContentModel,
      commentedContentIdentifier
    }) {
      commit('setIsSavingComment', true)

      try {
        const response = await axiosBase.post(
          `/comments/${commentedContentAppLabel}/${commentedContentModel}/${commentedContentIdentifier}/`,
          { content },
          {
          // TODO: transform request and response globally on all Axios requests
            transformRequest: [camelcaseTransformRequest, ...axiosBase.defaults.transformRequest],
            transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
          }
        )

        const { data: commentData } = response

        commit('addCommentData', commentData)

        return true
      } catch (err) {
        dispatch('alerts/triggerErrorAlert', {
          message: (
            'Ha ocurrido un error al intentar grabar un comentario')
        }, { root: true })

        commit('setIsSavingComment', false)

        return false
      }
    },

    async getCommentsData(
      { commit, dispatch },
      { commentedContentAppLabel, commentedContentModel, commentedContentIdentifier }
    ) {
      try {
        const response = await axiosBase.get(
          `/comments/${commentedContentAppLabel}/${commentedContentModel}/${commentedContentIdentifier}/`,
          {
            // TODO: transform request and response globally on all Axios requests
            transformResponse: [camelcaseTransformResponse, ...axiosBase.defaults.transformResponse]
          }
        )

        const { data: { results: commentsData } } = response

        commit('setCommentsData', commentsData)
        return true
      } catch (err) {
        dispatch('alerts/triggerErrorAlert', {
          message: (
            'Ha ocurrido un error al intentar obtener la información de los comentarios')
        }, { root: true })
        return false
      }
    }
  }
}
