const propertiesNames = {
  // cper
  numberOfMembers: 'numberOfMembers',
  // capor
  membersWhoHaveIncome: 'membersWhoHaveIncome',
  // ned
  educationLevel: 'educationLevel',
  // c_medica
  medicalCoverage: 'medicalCoverage',
  // estado
  workStatus: 'workStatus',
  // cat_ocup
  workType: 'workType',
  // categ
  workCategory: 'workCategory',
  // calif
  workQualification: 'workQualification',
  // cantpers
  numberOfEmployees: 'numberOfEmployees',
  // intens
  workIntensity: 'workIntensity'
}

const propertiesPosition = {
  [propertiesNames.numberOfMembers]: 0,
  [propertiesNames.membersWhoHaveIncome]: 1,
  [propertiesNames.educationLevel]: 2,
  [propertiesNames.medicalCoverage]: 3,
  [propertiesNames.workStatus]: 4,
  [propertiesNames.workType]: 5,
  [propertiesNames.workCategory]: 6,
  [propertiesNames.workQualification]: 7,
  [propertiesNames.numberOfEmployees]: 8,
  [propertiesNames.workIntensity]: 9
}

const propertiesOptions = {
  [propertiesNames.workStatus]: [
    {
      value: 1,
      description: 'Si, actualmente trabaja'
    },
    {
      value: 2,
      description: 'No trabaja pero está buscando activamente trabajo'
    },
    {
      value: 3,
      description: 'No, está jubilado y/o pensionado'
    },
    {
      value: 4,
      description: 'No, sus ingresos provienen de alquileres, inversiones o negocios'
    },
    {
      value: 5,
      description: 'No, pero tiene otra fuente de ingresos'
    }
  ],
  [propertiesNames.educationLevel]: [
    {
      value: 1,
      description: 'Sin estudios / Primaria incompleta'
    },
    {
      value: 2,
      description: 'Primaria completa / Secundaria incompleta'
    },
    {
      value: 3,
      description: 'Secundaria completa / Terciario o Universitario incompleto'
    },
    {
      value: 4,
      description: 'Terciario o Universitario completo'
    }
  ],
  [propertiesNames.medicalCoverage]: [
    {
      value: 1,
      description: 'Si'
    },
    {
      value: 2,
      description: 'No, en caso de ser necesario se atiende en hospital público o con un profesional privado que busca'
        + ' en el momento'
    },
    {
      value: 3,
      description: 'No sé'
    }
  ],
  [propertiesNames.workType]: [
    {
      value: 1,
      description: 'Como dueño o socio de una empresa, estudio o comercio'
    },
    {
      value: 2,
      description: 'En algún organismo público o empresa privada de cualquier tipo, como fábrica, taller, oficina,'
        + ' obra, comercio o con una persona que le da trabajo'
    },
    {
      value: 3,
      description: 'Trabajando solo, por su cuenta, sin empleados'
    }
  ],
  [propertiesNames.workCategory]: [
    {
      value: 1,
      description: 'Directivo'
    },
    {
      value: 2,
      description: 'Jefe'
    },
    {
      value: 3,
      description: 'Trabajador'
    }
  ],
  [propertiesNames.workQualification]: [
    {
      value: 1,
      description: 'Profesional'
    },
    {
      value: 2,
      description: 'Técnico'
    },
    {
      value: 3,
      description: 'Operativo'
    },
    {
      value: 4,
      description: 'No calificado'
    }
  ],
  [propertiesNames.numberOfEmployees]: [
    {
      value: 1,
      description: 'Hasta 5 personas'
    },
    {
      value: 2,
      description: 'Entre 6 y 40'
    },
    {
      value: 3,
      description: 'Entre 41 y 200'
    },
    {
      value: 4,
      description: 'Más de 200'
    },
    {
      value: 5,
      description: 'No sé'
    }
  ],
  [propertiesNames.workIntensity]: [
    {
      value: 1,
      description: 'Si'
    },
    {
      value: 2,
      description: 'No'
    }
  ]
}

// table[c_medica][ned][RIM][categ][cantpers/calif]
const tableA = {
  1: {
    4: {
      1: {
        1: {
          1: 5, 2: 5, 3: 6, 4: 6
        },
        2: {
          1: 4, 2: 5, 3: 5, 4: 5
        },
        3: {
          1: 5, 2: 4, 3: 4, 4: 3
        }
      },
      2: {
        1: {
          1: 6, 2: 6, 3: 6, 4: 6
        },
        2: {
          1: 5, 2: 5, 3: 5, 4: 6
        },
        3: {
          1: 5, 2: 5, 3: 4, 4: 3
        }
      },
      3: {
        1: {
          1: 6, 2: 6, 3: 7, 4: 7
        },
        2: {
          1: 5, 2: 6, 3: 6, 4: 6
        },
        3: {
          1: 6, 2: 5, 3: 5, 4: 4
        }
      }
    },
    3: {
      1: {
        1: {
          1: 5, 2: 5, 3: 5, 4: 5
        },
        2: {
          1: 4, 2: 4, 3: 4, 4: 5
        },
        3: {
          1: 4, 2: 4, 3: 3, 4: 2
        }
      },
      2: {
        1: {
          1: 5, 2: 5, 3: 6, 4: 6
        },
        2: {
          1: 4, 2: 5, 3: 5, 4: 5
        },
        3: {
          1: 5, 2: 4, 3: 4, 4: 3
        }
      },
      3: {
        1: {
          1: 6, 2: 6, 3: 6, 4: 6
        },
        2: {
          1: 5, 2: 5, 3: 5, 4: 6
        },
        3: {
          1: 5, 2: 5, 3: 4, 4: 3
        }
      }
    },
    2: {
      1: {
        1: {
          1: 4, 2: 4, 3: 5, 4: 5
        },
        2: {
          1: 3, 2: 4, 3: 4, 4: 4
        },
        3: {
          1: 4, 2: 3, 3: 3, 4: 2
        }
      },
      2: {
        1: {
          1: 5, 2: 5, 3: 5, 4: 5
        },
        2: {
          1: 4, 2: 4, 3: 5, 4: 5
        },
        3: {
          1: 5, 2: 4, 3: 4, 4: 3
        }
      },
      3: {
        1: {
          1: 5, 2: 5, 3: 6, 4: 6
        },
        2: {
          1: 4, 2: 5, 3: 5, 4: 5
        },
        3: {
          1: 5, 2: 4, 3: 4, 4: 3
        }
      }
    },
    1: {
      1: {
        1: {
          1: 4, 2: 4, 3: 4, 4: 4
        },
        2: {
          1: 3, 2: 3, 3: 4, 4: 4
        },
        3: {
          1: 4, 2: 3, 3: 3, 4: 2
        }
      },
      2: {
        1: {
          1: 4, 2: 5, 3: 5, 4: 5
        },
        2: {
          1: 3, 2: 4, 3: 4, 4: 4
        },
        3: {
          1: 4, 2: 3, 3: 3, 4: 2
        }
      },
      3: {
        1: {
          1: 5, 2: 5, 3: 5, 4: 5
        },
        2: {
          1: 4, 2: 4, 3: 5, 4: 5
        },
        3: {
          1: 5, 2: 4, 3: 4, 4: 3
        }
      }
    }
  },
  2: {
    4: {
      1: {
        1: {
          1: 5, 2: 5, 3: 6, 4: 6
        },
        2: {
          1: 4, 2: 4, 3: 5, 4: 5
        },
        3: {
          1: 5, 2: 4, 3: 4, 4: 3
        }
      },
      2: {
        1: {
          1: 6, 2: 6, 3: 6, 4: 6
        },
        2: {
          1: 4, 2: 5, 3: 5, 4: 5
        },
        3: {
          1: 5, 2: 4, 3: 4, 4: 3
        }
      },
      3: {
        1: {
          1: 6, 2: 6, 3: 7, 4: 7
        },
        2: {
          1: 5, 2: 5, 3: 6, 4: 6
        },
        3: {
          1: 6, 2: 5, 3: 5, 4: 4
        }
      }
    },
    3: {
      1: {
        1: {
          1: 5, 2: 5, 3: 5, 4: 5
        },
        2: {
          1: 3, 2: 4, 3: 4, 4: 4
        },
        3: {
          1: 4, 2: 3, 3: 3, 4: 2
        }
      },
      2: {
        1: {
          1: 5, 2: 5, 3: 6, 4: 6
        },
        2: {
          1: 4, 2: 4, 3: 5, 4: 5
        },
        3: {
          1: 5, 2: 4, 3: 4, 4: 3
        }
      },
      3: {
        1: {
          1: 6, 2: 6, 3: 6, 4: 6
        },
        2: {
          1: 4, 2: 5, 3: 5, 4: 5
        },
        3: {
          1: 5, 2: 4, 3: 4, 4: 3
        }
      }
    },
    2: {
      1: {
        1: {
          1: 4, 2: 4, 3: 5, 4: 5
        },
        2: {
          1: 3, 2: 3, 3: 4, 4: 4
        },
        3: {
          1: 4, 2: 3, 3: 3, 4: 2
        }
      },
      2: {
        1: {
          1: 5, 2: 5, 3: 5, 4: 5
        },
        2: {
          1: 3, 2: 4, 3: 4, 4: 4
        },
        3: {
          1: 4, 2: 3, 3: 3, 4: 2
        }
      },
      3: {
        1: {
          1: 5, 2: 5, 3: 6, 4: 6
        },
        2: {
          1: 4, 2: 4, 3: 5, 4: 5
        },
        3: {
          1: 5, 2: 4, 3: 4, 4: 3
        }
      }
    },
    1: {
      1: {
        1: {
          1: 4, 2: 4, 3: 4, 4: 4
        },
        2: {
          1: 3, 2: 3, 3: 3, 4: 3
        },
        3: {
          1: 3, 2: 3, 3: 2, 4: 1
        }
      },
      2: {
        1: {
          1: 4, 2: 5, 3: 5, 4: 5
        },
        2: {
          1: 3, 2: 3, 3: 4, 4: 4
        },
        3: {
          1: 4, 2: 3, 3: 3, 4: 2
        }
      },
      3: {
        1: {
          1: 5, 2: 5, 3: 5, 4: 5
        },
        2: {
          1: 4, 2: 4, 3: 4, 4: 4
        },
        3: {
          1: 4, 2: 4, 3: 3, 4: 2
        }
      }
    }
  }
}

// table[c_medica][ned][RIM][cantpers]
const tableB = {
  4: {
    1: {
      1: 5, 2: 5, 3: 6, 4: 6
    },
    2: {
      1: 6, 2: 6, 3: 6, 4: 6
    },
    3: {
      1: 6, 2: 6, 3: 7, 4: 7
    }
  },
  3: {
    1: {
      1: 5, 2: 5, 3: 5, 4: 5
    },
    2: {
      1: 5, 2: 5, 3: 6, 4: 6
    },
    3: {
      1: 6, 2: 6, 3: 6, 4: 6
    }
  },
  2: {
    1: {
      1: 4, 2: 4, 3: 5, 4: 5
    },
    2: {
      1: 5, 2: 5, 3: 5, 4: 5
    },
    3: {
      1: 5, 2: 5, 3: 6, 4: 6
    }
  },
  1: {
    1: {
      1: 4, 2: 4, 3: 4, 4: 4
    },
    2: {
      1: 4, 2: 5, 3: 5, 4: 5
    },
    3: {
      1: 5, 2: 5, 3: 5, 4: 5
    }
  }
}

// table[c_medica][ned][RIM][calif][intens]
const tableC = {
  1: {
    4: {
      1: {
        1: { 1: 6, 2: 5 },
        2: { 1: 5, 2: 4 },
        3: { 1: 4, 2: 3 },
        4: { 1: 3, 2: 3 }
      },
      2: {
        1: { 1: 6, 2: 5 },
        2: { 1: 5, 2: 5 },
        3: { 1: 4, 2: 3 },
        4: { 1: 4, 2: 3 }
      },
      3: {
        1: { 1: 6, 2: 6 },
        2: { 1: 6, 2: 5 },
        3: { 1: 5, 2: 4 },
        4: { 1: 4, 2: 4 }
      }
    },
    3: {
      1: {
        1: { 1: 5, 2: 4 },
        2: { 1: 4, 2: 4 },
        3: { 1: 3, 2: 2 },
        4: { 1: 3, 2: 2 }
      },
      2: {
        1: { 1: 6, 2: 5 },
        2: { 1: 5, 2: 4 },
        3: { 1: 4, 2: 3 },
        4: { 1: 3, 2: 3 }
      },
      3: {
        1: { 1: 6, 2: 5 },
        2: { 1: 5, 2: 5 },
        3: { 1: 4, 2: 3 },
        4: { 1: 4, 2: 3 }
      }
    },
    2: {
      1: {
        1: { 1: 5, 2: 4 },
        2: { 1: 4, 2: 3 },
        3: { 1: 3, 2: 2 },
        4: { 1: 2, 2: 2 }
      },
      2: {
        1: { 1: 5, 2: 5 },
        2: { 1: 5, 2: 4 },
        3: { 1: 3, 2: 2 },
        4: { 1: 3, 2: 2 }
      },
      3: {
        1: { 1: 6, 2: 5 },
        2: { 1: 5, 2: 4 },
        3: { 1: 4, 2: 3 },
        4: { 1: 3, 2: 3 }
      }
    },
    1: {
      1: {
        1: { 1: 4, 2: 4 },
        2: { 1: 4, 2: 3 },
        3: { 1: 2, 2: 2 },
        4: { 1: 2, 2: 1 }
      },
      2: {
        1: { 1: 5, 2: 4 },
        2: { 1: 4, 2: 3 },
        3: { 1: 3, 2: 2 },
        4: { 1: 3, 2: 2 }
      },
      3: {
        1: { 1: 5, 2: 5 },
        2: { 1: 5, 2: 4 },
        3: { 1: 3, 2: 3 },
        4: { 1: 3, 2: 2 }
      }
    }
  },
  2: {
    4: {
      1: {
        1: { 1: 5, 2: 5 },
        2: { 1: 5, 2: 4 },
        3: { 1: 3, 2: 2 },
        4: { 1: 3, 2: 2 }
      },
      2: {
        1: { 1: 6, 2: 5 },
        2: { 1: 5, 2: 4 },
        3: { 1: 4, 2: 3 },
        4: { 1: 3, 2: 3 }
      },
      3: {
        1: { 1: 6, 2: 6 },
        2: { 1: 6, 2: 5 },
        3: { 1: 4, 2: 3 },
        4: { 1: 4, 2: 3 }
      }
    },
    3: {
      1: {
        1: { 1: 5, 2: 4 },
        2: { 1: 4, 2: 3 },
        3: { 1: 3, 2: 2 },
        4: { 1: 2, 2: 2 }
      },
      2: {
        1: { 1: 5, 2: 5 },
        2: { 1: 5, 2: 4 },
        3: { 1: 3, 2: 2 },
        4: { 1: 3, 2: 2 }
      },
      3: {
        1: { 1: 6, 2: 5 },
        2: { 1: 5, 2: 4 },
        3: { 1: 4, 2: 3 },
        4: { 1: 3, 2: 3 }
      }
    },
    2: {
      1: {
        1: { 1: 4, 2: 4 },
        2: { 1: 4, 2: 3 },
        3: { 1: 2, 2: 2 },
        4: { 1: 2, 2: 1 }
      },
      2: {
        1: { 1: 5, 2: 4 },
        2: { 1: 4, 2: 3 },
        3: { 1: 3, 2: 2 },
        4: { 1: 3, 2: 2 }
      },
      3: {
        1: { 1: 5, 2: 5 },
        2: { 1: 5, 2: 4 },
        3: { 1: 3, 2: 3 },
        4: { 1: 3, 2: 2 }
      }
    },
    1: {
      1: {
        1: { 1: 4, 2: 3 },
        2: { 1: 3, 2: 3 },
        3: { 1: 2, 2: 1 },
        4: { 1: 2, 2: 1 }
      },
      2: {
        1: { 1: 5, 2: 4 },
        2: { 1: 4, 2: 3 },
        3: { 1: 2, 2: 2 },
        4: { 1: 2, 2: 1 }
      },
      3: {
        1: { 1: 5, 2: 4 },
        2: { 1: 4, 2: 4 },
        3: { 1: 3, 2: 2 },
        4: { 1: 3, 2: 2 }
      }
    }
  }
}

// table[ned][RIM][cat_ocup][calif/categ]
const tableD = {
  4: {
    1: {
      3: {
        1: 5, 2: 4, 3: 3, 4: 2
      },
      2: { 1: 5, 2: 3, 3: 2 },
      1: 5
    },
    2: {
      3: {
        1: 5, 2: 4, 3: 3, 4: 2
      },
      2: { 1: 5, 2: 4, 3: 3 },
      1: 5
    },
    3: {
      3: {
        1: 6, 2: 5, 3: 4, 4: 3
      },
      2: { 1: 6, 2: 4, 3: 3 },
      1: 6
    }
  },
  3: {
    1: {
      3: {
        1: 4, 2: 3, 3: 2, 4: 1
      },
      2: { 1: 4, 2: 3, 3: 2 },
      1: 4
    },
    2: {
      3: {
        1: 5, 2: 4, 3: 3, 4: 2
      },
      2: { 1: 5, 2: 3, 3: 2 },
      1: 5
    },
    3: {
      3: {
        1: 5, 2: 4, 3: 3, 4: 2
      },
      2: { 1: 5, 2: 4, 3: 3 },
      1: 5
    }
  },
  2: {
    1: {
      3: {
        1: 4, 2: 3, 3: 2, 4: 1
      },
      2: { 1: 4, 2: 2, 3: 1 },
      1: 4
    },
    2: {
      3: {
        1: 4, 2: 3, 3: 2, 4: 2
      },
      2: { 1: 4, 2: 3, 3: 2 },
      1: 4
    },
    3: {
      3: {
        1: 5, 2: 4, 3: 3, 4: 2
      },
      2: { 1: 5, 2: 3, 3: 2 },
      1: 5
    }
  },
  1: {
    1: {
      3: {
        1: 4, 2: 2, 3: 2, 4: 1
      },
      2: { 1: 4, 2: 2, 3: 1 },
      1: 4
    },
    2: {
      3: {
        1: 4, 2: 3, 3: 2, 4: 1
      },
      2: { 1: 4, 2: 3, 3: 1 },
      1: 4
    },
    3: {
      3: {
        1: 5, 2: 3, 3: 2, 4: 2
      },
      2: { 1: 5, 2: 3, 3: 2 },
      1: 5
    }
  }
}

// table[ned][RIM][estado][c_medica]
const tableE = {
  4: {
    1: { 3: 4, 4: { 1: 6, 2: 5 }, 5: { 1: 5, 2: 4 } },
    2: { 3: 5, 4: { 1: 6, 2: 5 }, 5: { 1: 5, 2: 4 } },
    3: { 3: 5, 4: { 1: 7, 2: 6 }, 5: { 1: 6, 2: 5 } }
  },
  3: {
    1: { 3: 3, 4: { 1: 6, 2: 5 }, 5: { 1: 4, 2: 4 } },
    2: { 3: 4, 4: { 1: 6, 2: 5 }, 5: { 1: 4, 2: 4 } },
    3: { 3: 4, 4: { 1: 7, 2: 6 }, 5: { 1: 5, 2: 5 } }
  },
  2: {
    1: { 3: 2, 4: { 1: 5, 2: 4 }, 5: { 1: 4, 2: 3 } },
    2: { 3: 3, 4: { 1: 5, 2: 4 }, 5: { 1: 4, 2: 3 } },
    3: { 3: 3, 4: { 1: 6, 2: 5 }, 5: { 1: 5, 2: 4 } }
  },
  1: {
    1: { 3: 1, 4: { 1: 3, 2: 3 }, 5: { 1: 3, 2: 2 } },
    2: { 3: 2, 4: { 1: 3, 2: 3 }, 5: { 1: 3, 2: 2 } },
    3: { 3: 2, 4: { 1: 4, 2: 4 }, 5: { 1: 4, 2: 3 } }
  }
}

// table[cper][capor]
const tableRIM = {
  1: { 1: 3 },
  2: { 1: 2, 2: 3 },
  3: { 1: 1, 2: 2, 3: 3 },
  4: {
    1: 1, 2: 2, 3: 3, 4: 3
  },
  5: {
    1: 1, 2: 1, 3: 2, 4: 3, 5: 3
  },
  6: {
    1: 1, 2: 1, 3: 2, 4: 2, 5: 3, 6: 3
  },
  7: {
    1: 1, 2: 1, 3: 2, 4: 2, 5: 3, 6: 3, 7: 3
  },
  8: {
    1: 1, 2: 1, 3: 1, 4: 2, 5: 2, 6: 3, 7: 3, 8: 3
  },
  9: {
    1: 1, 2: 1, 3: 1, 4: 2, 5: 2, 6: 2, 7: 3, 8: 3
  },
  10: {
    1: 1, 2: 1, 3: 1, 4: 1, 5: 2, 6: 2, 7: 3, 8: 3
  },
  11: {
    1: 1, 2: 1, 3: 1, 4: 1, 5: 2, 6: 2, 7: 2, 8: 3
  },
  12: {
    1: 1, 2: 1, 3: 1, 4: 1, 5: 2, 6: 2, 7: 2, 8: 2
  },
  13: {
    1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 2, 7: 2, 8: 2
  },
  14: {
    1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 2, 7: 2, 8: 2
  },
  15: {
    1: 1, 2: 1, 3: 1, 4: 1, 5: 1, 6: 1, 7: 2, 8: 2
  }
}

export {
  propertiesNames, propertiesOptions, propertiesPosition, tableA, tableB, tableC, tableD, tableE, tableRIM
}
