const participationStages = {
  forum: 'forum',
  login: 'login',
  noProjectData: 'no-project-data',
  noParticipantData: 'no-participant-data',
  projectDetail: 'project-detail',
  projectHome: 'project-home',
  termsAndConditions: 'participation-terms-and-conditions',
  activity: 'participation-activity'
}

export default participationStages
