import { axiosBase } from '../../api/axios-base' // eslint-disable-line import/no-cycle

const initialState = {
  step: 1,
  networkError: false,
  invitationError: false,
  loading: false,
  step1Submitted: false,
  step2Submitted: false,
  success: false
}

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    networkError(state) {
      Object.assign(state, {
        loading: false,
        networkError: true
      })
    },
    invitationError(state) {
      Object.assign(state, {
        loading: false,
        invitationError: true
      })
    },
    loading(state) {
      Object.assign(state, {
        loading: true,
        networkError: false
      })
    },
    success(state) {
      Object.assign(state, {
        loading: false,
        success: true
      })
    },
    step1Submitted(state) {
      state.step1Submitted = true
    },
    step2Submitted(state) {
      state.step2Submitted = true
    },
    resetState(state) {
      Object.assign(state, initialState)
    },
    registrationStep(state, stepNumber) {
      state.step = stepNumber
    }
  },
  actions: {
    completeRegistrationStep1(context) {
      context.commit('registrationStep', 2)
    },
    completeRegistrationStep2(context, {
      invitationCode,
      firstName,
      lastName,
      company,
      password
    }) {
      context.commit('loading')

      return new Promise((resolve) => {
        axiosBase.post('/users/register/', {
          invitation_code: invitationCode,
          first_name: firstName,
          last_name: lastName,
          company,
          password
        }).then(
          (response) => {
            const { data: { access, refresh } } = response

            context.commit('updateLocalStorage', {
              access,
              refresh
            }, { root: true })

            context.commit('success')
            // Reset invitation state
            context.commit('Invitations/resetState', null, { root: true })
            resolve()
          }
        ).catch((error) => {
          if (error.response && error.response.status === 400) {
            // Invalid token
            context.commit('invitationError')
            resolve()
          } else {
            // Request has failed because of network issues
            context.commit('networkError')
            resolve()
          }
        })
      })
    }
  }
}
